import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sxpctx } from "../..";
import {
    CurriculumQuery,
    Participant,
    ParticipantQuery,
    RegistrationQuery,
} from "@sxp-api-lib/index";
import {
    ActionIcon,
    Box,
    Button,
    List,
    Select,
    Table,
    ThemeIcon,
} from "@mantine/core";
import i18n from "../../i18n";
import { IconEdit } from "@tabler/icons-react";

export default function ParticipantInspector() {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [curriculumId, setCurriculumId] = useState(
        sxpctx.curriculums.a[0].id
    );

    useEffect(() => {
        sxpctx
            .getQuery(ParticipantQuery)
            .fillContext({ id: id }, true)
            .then(() => {
                if (sxpctx.participant) {
                    sxpctx
                        .getQuery(RegistrationQuery)
                        .fillContext({ participantId: id }, true)
                        .then(() => {
                            setLoading(false);
                        });
                } else {
                    setLoading(false);
                }
            });
    }, []);

    const curriculumData = sxpctx.curriculums.a.map((e) => {
        return { label: e.label, value: e.id };
    });

    const curriculum = sxpctx.curriculums.d[curriculumId];
    const courses = curriculum.getCourses().map((e) => {
        return { course: e, registration: e.getAssociatedRegistration() };
    });

    return (
        <div>
            <Button
                onClick={() => {
                    window.location.hash = "/participant-manager";
                }}
            >
                Retour
            </Button>
            <h2>
                {sxpctx.participant?.first_name +
                    " " +
                    sxpctx.participant?.last_name}
            </h2>
            <Select
                data={curriculumData}
                value={curriculumId}
                onChange={(target) => setCurriculumId(target || "")}
            ></Select>
            <Table
                horizontalSpacing="sm"
                verticalSpacing="sm"
                fontSize="xs"
                highlightOnHover
                style={{ paddingTop: "20px" }}
            >
                <thead>
                    <tr>
                        <th>{i18n.t("common.chapter")}</th>
                        <th>{i18n.t("common.name")}</th>
                        <th>{i18n.t("common.registration")}</th>
                    </tr>
                </thead>
                <tbody>
                    {courses.map((e) => (
                        <tr key={e.course.id}>
                            <td>
                                <ThemeIcon color="blue" size={24} radius="xl">
                                    {e.course.getChapter()}
                                </ThemeIcon>
                            </td>
                            <td>{e.course.label}</td>
                            <td>
                                {e.registration ? (
                                    <ActionIcon onClick={() => {}}>
                                        <IconEdit />
                                    </ActionIcon>
                                ) : (
                                    ""
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
