import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Flex, Group, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import {
    Calendar,
    CalendarFilter,
    CalendarQuery,
    SXPContext,
    dateToLocalString,
} from "@sxp-api-lib/index";
import { IconCopy, IconEdit, IconTrash } from "@tabler/icons-react";
import i18next from "i18next";
import {
    MRT_ColumnFiltersState,
    MRT_SortingState,
    type MRT_ColumnDef,
} from "mantine-react-table";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AppContext } from "../../AppContext";
import CustomMantineReactTable from "../../components/CustomMantineReactTable";
import { CustomMantineReactTableParams } from "../../components/CustomMantineReactTable/CustomMantineReactTable";
import { postProcessCalendarPayload } from "../../helpers/CalendarHelper";
import { isUserSuperAdmin } from "../../helpers/UserHelper";
import { sxpctx } from "../..";

type CalendarManagerInnerType = {
    id: string;
    startTime: string;
    endTime: string;
    firstDate: Date;
    lastDate: Date;
    weekDay: string;
    region: string;
    calendarFormat: string;
    extra: string;
};

export default function CalendarManager() {
    const { t } = useTranslation();
    const context = useContext(AppContext);

    const [refreshLoading, setRefreshLoading] = useState(false);

    const queryClient = useQueryClient();

    const deleteProduct = useMutation({
        mutationFn: async (id: string) => {
            return await sxpctx.getQuery(CalendarQuery).delete(id);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["calendars"] });
        },
    });

    const useGetData = ({
        columnFilters,
        sorting,
        pagination,
    }: CustomMantineReactTableParams) => {
        const filter = createQueryFilter(columnFilters);
        const sort = getSort(sorting);

        const fetchCalendars = async () => {
            const promises = [];

            promises.push(
                sxpctx
                    .getQuery(CalendarQuery)
                    .fetchManyWithPagination(
                        filter,
                        pagination.pageSize,
                        pagination.pageIndex + 1,
                        sort
                    )
                    .then((response) => {
                        return {
                            data: response.data.map((e) =>
                                prepareCalendarForTableView(e)
                            ),
                            meta: response.meta,
                        };
                    })
            );

            return Promise.all(promises).then(([calendars]) => {
                return calendars as {
                    data: CalendarManagerInnerType[];
                    meta: { total_count: number };
                };
            });
        };

        return useQuery<{
            data: CalendarManagerInnerType[];
            meta: { total_count: number };
        }>({
            queryKey: ["calendars", pagination, columnFilters, sorting],
            queryFn: () => fetchCalendars(),
            keepPreviousData: true,
            staleTime: 30_000,
            refetchOnWindowFocus: false,
        });
    };

    const columns: MRT_ColumnDef<CalendarManagerInnerType>[] = [
        {
            accessorKey: "id",
            header: "ID",
            enableHiding: true,
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "startTime",
            header: t("calendarManager.startTime"),
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "endTime",
            header: t("calendarManager.endTime"),
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "firstDate",
            header: t("calendarManager.firstDate"),
            filterVariant: "date-range",
            Cell: ({ cell }) =>
                t("{{val, sxpDateRaw}}", { val: cell.getValue<Date>() }),
            enableEditing: false,
        },
        {
            accessorKey: "lastDate",
            header: t("calendarManager.lastDate"),
            filterVariant: "date-range",
            Cell: ({ cell }) =>
                t("{{val, sxpDateRaw}}", { val: cell.getValue<Date>() }),
            enableEditing: false,
        },
        {
            accessorKey: "weekDay",
            header: t("calendarManager.weekDay"),
            mantineFilterSelectProps: ({ column, table }) => ({
                data: getWeekdayList(),
            }),
            filterVariant: "select",
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "region",
            header: t("calendarManager.region"),
            mantineFilterSelectProps: ({ column, table }) => ({
                data: sxpctx.regions.a.map((e) => ({
                    value: e.id,
                    label: e.short_name,
                })),
            }),
            Cell: ({ cell }) =>
                sxpctx.regions.d[cell.getValue() as string]?.short_name,
            filterVariant: "select",
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "calendarFormat",
            header: t("calendarManager.format"),
            mantineFilterSelectProps: ({ column, table }) => ({
                data: sxpctx.calendarFormats.a.map((e) => ({
                    value: e.id,
                    label: e.name,
                })),
            }),
            Cell: ({ cell }) =>
                sxpctx.calendarFormats.d[cell.getValue() as string]?.name,
            filterVariant: "select",
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "suffix",
            header: t("calendarManager.session"),
            enableSorting: true,
            sortDescFirst: true,
            enableEditing: false,
        },
        {
            accessorKey: "extra",
            header: t("calendarManager.extra"),
            enableSorting: false,
            enableEditing: false,
        },
    ];

    return (
        <CustomMantineReactTable<CalendarManagerInnerType>
            id={"calendar-manager"}
            columns={columns}
            useGetData={useGetData}
            actionCount={3}
            renderRowActions={({ cell, row, table }) => (
                <Flex gap="md">
                    <Tooltip label="Edit">
                        <ActionIcon
                            onClick={() => {
                                if (row.original.calendarFormat === "camp") {
                                    modals.openContextModal({
                                        modal: "CampCalendarManagerEditModal",
                                        title: t(
                                            "calendarManager.createNewCamp"
                                        ),
                                        closeOnEscape: false,
                                        closeOnClickOutside: false,
                                        size: "lg",
                                        styles: {
                                            inner: {
                                                width: "-webkit-fill-available", // Fixes alignment error with mantine
                                            },
                                        },
                                        innerProps: {
                                            id: row.original.id,
                                        },
                                    });
                                }

                                if (row.original.calendarFormat === "weekly") {
                                    modals.openContextModal({
                                        modal: "WeeklyCalendarManagerEditModal",
                                        title: t(
                                            "calendarManager.createNewWeekly"
                                        ),
                                        closeOnEscape: false,
                                        closeOnClickOutside: false,
                                        size: "lg",
                                        styles: {
                                            inner: {
                                                width: "-webkit-fill-available", // Fixes alignment error with mantine
                                            },
                                        },
                                        innerProps: {
                                            id: row.original.id,
                                        },
                                    });
                                }
                            }}
                        >
                            <IconEdit />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Copy">
                        <ActionIcon
                            onClick={() => {
                                if (row.original.calendarFormat === "camp") {
                                    modals.openContextModal({
                                        modal: "CampCalendarManagerEditModal",
                                        title: t(
                                            "calendarManager.createNewCamp"
                                        ),
                                        closeOnEscape: false,
                                        closeOnClickOutside: false,
                                        size: "lg",
                                        styles: {
                                            inner: {
                                                width: "-webkit-fill-available", // Fixes alignment error with mantine
                                            },
                                        },
                                        innerProps: {
                                            copyId: row.original.id,
                                        },
                                    });
                                }

                                if (row.original.calendarFormat === "weekly") {
                                    modals.openContextModal({
                                        modal: "WeeklyCalendarManagerEditModal",
                                        title: t(
                                            "calendarManager.createNewWeekly"
                                        ),
                                        closeOnEscape: false,
                                        closeOnClickOutside: false,
                                        size: "lg",
                                        styles: {
                                            inner: {
                                                width: "-webkit-fill-available", // Fixes alignment error with mantine
                                            },
                                        },
                                        innerProps: {
                                            copyId: row.original.id,
                                        },
                                    });
                                }
                            }}
                        >
                            <IconCopy />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Delete">
                        <ActionIcon
                            color="red"
                            onClick={() =>
                                modals.openConfirmModal({
                                    title: "Are you sure you want to delete this?",
                                    children: (
                                        <Text>
                                            Are you sure you want to delete{" "}
                                            {row.original.id}? This action
                                            cannot be undone.
                                        </Text>
                                    ),
                                    labels: {
                                        confirm: "Delete",
                                        cancel: "Cancel",
                                    },
                                    confirmProps: { color: "red" },
                                    onConfirm: () => {
                                        deleteProduct.mutate(row.original.id);
                                    },
                                })
                            }
                        >
                            <IconTrash />
                        </ActionIcon>
                    </Tooltip>
                </Flex>
            )}
            renderTopToolbarCustomActions={({ table }) => (
                <Group>
                    <Button
                        onClick={() => {
                            modals.openContextModal({
                                modal: "CampCalendarManagerEditModal",
                                title: t(
                                    "calendarManager.createNewWeeklyCalendar"
                                ),
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                size: "lg",
                                styles: {
                                    inner: {
                                        width: "-webkit-fill-available", // Fixes alignment error with mantine
                                    },
                                },
                                innerProps: {},
                            });
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <span style={{ paddingLeft: "5px" }}>
                            {t("calendarManager.camp")}
                        </span>
                    </Button>
                    <Button
                        onClick={() => {
                            modals.openContextModal({
                                modal: "WeeklyCalendarManagerEditModal",
                                title: t(
                                    "calendarManager.createNewWeeklyCalendar"
                                ),
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                size: "lg",
                                styles: {
                                    inner: {
                                        width: "-webkit-fill-available", // Fixes alignment error with mantine
                                    },
                                },
                                innerProps: {},
                            });
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <span style={{ paddingLeft: "5px" }}>
                            {t("calendarManager.weekly")}
                        </span>
                    </Button>
                    {isUserSuperAdmin(sxpctx.user) && (
                        <Button
                            loading={refreshLoading}
                            onClick={() => {
                                const fetchNext = (
                                    pageIndex: number,
                                    pageSize: number
                                ) => {
                                    sxpctx
                                        .getQuery(CalendarQuery)
                                        .fetchManyWithPagination(
                                            {},
                                            pageSize,
                                            pageIndex
                                        )
                                        .then(async (result) => {
                                            if (
                                                pageSize * (pageIndex - 1) <
                                                result.meta.total_count
                                            ) {
                                                for (
                                                    var i = 0;
                                                    i < result.data.length;
                                                    i++
                                                ) {
                                                    const payload =
                                                        postProcessCalendarPayload(
                                                            result.data[i]
                                                        );

                                                    await sxpctx
                                                        .getQuery(CalendarQuery)
                                                        .update({
                                                            id: payload.id,
                                                            calc_first_event_start:
                                                                payload.calc_first_event_start,
                                                            calc_last_event_end:
                                                                payload.calc_last_event_end,
                                                            name: payload.name,
                                                        });
                                                }

                                                fetchNext(
                                                    pageIndex + 1,
                                                    pageSize
                                                );
                                            } else {
                                                setRefreshLoading(false);
                                            }
                                        });
                                };

                                setRefreshLoading(true);
                                fetchNext(1, 10);
                            }}
                        >
                            <span style={{ paddingLeft: "5px" }}>
                                REFRESH NAMES
                            </span>
                        </Button>
                    )}
                </Group>
            )}
        />
    );
}

function prepareCalendarForTableView(calendar: Calendar) {
    const firstDateStart = new Date(calendar.events[0].start);
    const firstDateEnd = new Date(calendar.events[0].end);
    const lastDateStart = new Date(
        calendar.events[calendar.events.length - 1].start
    );

    return {
        id: calendar.id,
        startTime: i18next.t("{{val, sxpTimeRaw}}", {
            val: firstDateStart,
        }),
        endTime: i18next.t("{{val, sxpTimeRaw}}", {
            val: firstDateEnd,
        }),
        firstDate: firstDateStart,
        lastDate: lastDateStart,
        weekDay: i18next.t("{{val, sxpWeekDay}}", { val: firstDateStart }),
        region: calendar.region,
        calendarFormat: calendar.format,
        suffix: calendar.suffix,
        extra: calendar.extra,
    };
}

function createQueryFilter(columnFilters: MRT_ColumnFiltersState) {
    const filter = {} as CalendarFilter;

    const startTime = columnFilters.find((e) => e.id === "startTime");
    if (startTime) {
        const value = startTime.value;

        const hour = getHourFromString(value);

        if (hour) {
            filter.startTimeHour = hour;
        }

        const minute = getMinuteFromString(value);

        if (minute) {
            filter.startTimeMinute = minute;
        }
    }

    const endTime = columnFilters.find((e) => e.id === "endTime");
    if (endTime) {
        const value = endTime.value;

        const hour = getHourFromString(value);

        if (hour) {
            filter.endTimeHour = hour;
        }

        const minute = getMinuteFromString(value);

        if (minute) {
            filter.endTimeMinute = minute;
        }
    }

    const firstDate = columnFilters.find((e) => e.id === "firstDate");
    if (firstDate) {
        const [min, max] = firstDate.value as (Date | null)[];

        if (max) {
            const date = new Date(max);
            date.setDate(date.getDate() + 1);
            filter.firstDateLesserEquals =
                dateToLocalString(date).split("T")[0];
        }

        if (min) {
            filter.firstDateGreaterEquals =
                dateToLocalString(min).split("T")[0];
        }
    }

    const lastDate = columnFilters.find((e) => e.id === "lastDate");
    if (lastDate) {
        const [min, max] = lastDate.value as (Date | null)[];

        if (max) {
            const date = new Date(max);
            date.setDate(date.getDate() + 1);
            filter.lastDateLesserEquals = dateToLocalString(date).split("T")[0];
        }

        if (min) {
            filter.lastDateGreaterEquals = dateToLocalString(min).split("T")[0];
        }
    }

    const weekDay = columnFilters.find((e) => e.id === "weekDay");
    if (weekDay) {
        filter.weekday = weekDay.value as string;
    }

    const region = columnFilters.find((e) => e.id === "region");
    if (region) {
        filter.region = region.value as string;
    }

    const calendarFormat = columnFilters.find((e) => e.id === "calendarFormat");
    if (calendarFormat) {
        filter.format = calendarFormat.value as string;
    }

    const suffix = columnFilters.find((e) => e.id === "suffix");
    if (suffix) {
        filter.suffix = suffix.value as string;
    }

    const extra = columnFilters.find((e) => e.id === "extra");
    if (extra) {
        filter.extra = extra.value as string;
    }

    return filter;
}

function getSort(sortArray: MRT_SortingState) {
    if (sortArray.length === 0) {
        return undefined;
    }

    const sort = sortArray[0];

    const order = sort.desc ? "-" : "";

    if (sort.id === "firstDate") {
        return order + "calc_first_event_start";
    }

    if (sort.id === "lastDate") {
        return order + "calc_last_event_end";
    }

    if (sort.id === "suffix") {
        return order + "suffix";
    }

    return undefined;
}

function getHourFromString(str: any) {
    if (typeof str !== "string") {
        return "";
    }

    const digits = str.match(/\d/g);

    if (!digits || digits.length < 2) {
        return "";
    }

    return parseInt(digits.slice(0, 2).join("")).toString();
}

function getMinuteFromString(str: any) {
    if (typeof str !== "string") {
        return "";
    }

    const digits = str.match(/\d/g);

    if (!digits || digits.length < 4) {
        return "";
    }

    return parseInt(digits.slice(2, 4).join("")).toString();
}

function getWeekdayList() {
    const weekdays = [];

    for (let i = 1; i <= 7; i++) {
        const date = new Date(2022, 0, i + 1); // January 1, 2022 is a Saturday
        const weekday = i18next.t("{{val, sxpWeekDay}}", {
            val: date,
        });
        weekdays.push({ value: i.toString(), label: weekday });
    }

    return weekdays;
}

function getNavigatorLocale() {
    const compatibleLanguages = ["fr-CA", "fr-FR", "en-CA", "en-US"];
    const locales = navigator.languages.filter((e) =>
        compatibleLanguages.includes(e)
    );

    return locales.length > 0 ? locales[0] : "fr-CA";
}
