import { SXPContext } from "../../SXPContext";
import { arrayToObjectGeneric } from "../../helper/ArrayHelper";
import { GenericQuery } from "./GenericQuery";

export type ShopCategoryFilter = {
    id?: string;
    languageCode?: string;
    status?: "public" | "hidden" | "archived";
    shopCategoryGroupId?: string;
};

export class ShopCategory {
    private context: SXPContext;

    id: string;
    kind: "ShopCategory" = "ShopCategory";
    status: "public" | "hidden" | "archived";
    slug: string;
    name: string;
    language: string;
    label: string;
    shop_categories_group: string;
    description: string;
    calendar_format: string;
    prices: {
        currency: {
            code: string;
            exchange_rate: number;
        };
        base_price: number;
        prices: {
            quantity: number;
            type: "single" | "multiple";
            price: number;
        }[];
    };

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
        this.context = context;
    }

    public cloneDataOnly() {
        const { context, ...clone } = this as any;

        return clone;
    }

    getUnitPrice(quantity: number, type: "single" | "multiple") {
        var previousOrEqualsPrice = {
            quantity: 1,
            price: this.prices.base_price,
        };

        this.prices.prices
            .filter((e) => e.type === type)
            .forEach((e) => {
                if (
                    e.quantity >= previousOrEqualsPrice.quantity &&
                    e.quantity <= quantity
                ) {
                    previousOrEqualsPrice.quantity = e.quantity;
                    previousOrEqualsPrice.price = e.price;
                }
            });

        return previousOrEqualsPrice.price;
    }

    getTotalPrice(quantity: number, type: "single" | "multiple") {
        var previousOrEqualsPrice = {
            quantity: 1,
            price: this.prices.base_price,
        };

        this.prices.prices
            .filter((e) => e.type === type)
            .forEach((e) => {
                if (
                    e.quantity >= previousOrEqualsPrice.quantity &&
                    e.quantity <= quantity
                ) {
                    previousOrEqualsPrice.quantity = e.quantity;
                    previousOrEqualsPrice.price = e.price;
                }
            });

        return previousOrEqualsPrice.price * quantity;
    }
}

export class ShopCategoryQuery extends GenericQuery<
    ShopCategory,
    ShopCategoryFilter
> {
    constructor(context: SXPContext) {
        super(context, ShopCategory, ShopCategoryQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.language = data.language.code;
        data.shop_categories_group = data.shop_categories_group.id;
        data.calendar_format = data.calendar_format.id;

        return data;
    }

    public async fillContext(
        filter: ShopCategoryFilter,
        override: boolean = false
    ) {
        if (this.context.shopCategories.a.length === 0 || override) {
            return this.fetchMany(filter).then((data) => {
                return (this.context.shopCategories = arrayToObjectGeneric(
                    data,
                    "id"
                ));
            });
        }
    }

    public static getName() {
        return "ShopCategoryQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                status
                name
                language {
                    code
                }
                label
                shop_categories_group {
                    id
                }
                description
                calendar_format {
                    id
                }
                slug
                prices {
                    currency {
                        code
                        exchange_rate
                    }
                    base_price
                    prices
                }
            }
        `;
    }

    protected getQueryData(filter: ShopCategoryFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            data.filterArray.push(/* GraphQL */ `
                {
                    id: {
                        _eq: $id
                    }
                }
            `);
            data.filterParams.push("$id: String");
        }

        if (filter.languageCode) {
            data.filterArray.push(/* GraphQL */ `
                {
                    language: {
                        code: {
                            _eq: $languageCode
                        }
                    }
                }
            `);
            data.filterParams.push("$languageCode: String");
        }

        if (filter.status) {
            data.filterArray.push(/* GraphQL */ `
                {
                    status: {
                        _eq: $status
                    }
                }
            `);
            data.filterParams.push("$status: String");
        }

        if (filter.shopCategoryGroupId) {
            data.filterArray.push(/* GraphQL */ `
                {
                    shop_categories_group: {
                        id: {
                            _eq: $shopCategoryGroupId
                        }
                    }
                }
            `);
            data.filterParams.push("$shopCategoryGroupId: String");
        }

        data.variables = {
            id: filter.id,
            languageCode: filter.languageCode,
            status: filter.status,
            shopCategoryGroupId: filter.shopCategoryGroupId,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "ShopCategory";
    }

    protected getGraphQLName(): string {
        return "shop_categories";
    }

    protected getRestEndPoint(): string {
        return "/items/shop_categories";
    }
}
