import { Button } from "@mantine/core";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

export default function Test() {
    const context = useContext(AppContext);

    function handleOnTest() {
        return null;
    }

    return (
        <>
            <div>
                <Button onClick={handleOnTest}>Test</Button>
            </div>
        </>
    );
}
