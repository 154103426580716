import axios from "axios";
import { SXPConfig } from "..";

export type AuthToken = {
    access_token: string;
    expires: number;
    refresh_token: string;
};

export default class sxpapiStatic {
    public get(endpoint: string, authorization: string | AuthToken) {
        const config = this.createConfig(authorization);
        const url = SXPConfig.apiOrigin + endpoint;

        if (SXPConfig.debugLevel === "verbose") {
            console.log(config);
            console.log(url);
            console.log(authorization);
        }

        return axios.get(url, config);
    }

    public post(
        endpoint: string,
        data: any,
        authorization: string | AuthToken
    ) {
        const config = this.createConfig(authorization);
        const url = SXPConfig.apiOrigin + endpoint;

        if (SXPConfig.debugLevel === "verbose") {
            console.log(config);
            console.log(url);
            console.log(authorization);
        }

        return axios.post(url, data, config);
    }

    public patch(
        endpoint: string,
        data: any,
        authorization: string | AuthToken
    ) {
        const config = this.createConfig(authorization);
        const url = SXPConfig.apiOrigin + endpoint;

        if (SXPConfig.debugLevel === "verbose") {
            console.log(config);
            console.log(url);
            console.log(authorization);
        }

        return axios.patch(url, data, config);
    }

    public delete(
        endpoint: string,
        data: any,
        authorization: string | AuthToken
    ) {
        const config = this.createConfig(authorization, data);
        const url = SXPConfig.apiOrigin + endpoint;

        if (SXPConfig.debugLevel === "verbose") {
            console.log(config);
            console.log(url);
            console.log(authorization);
        }

        return axios.delete(url, config);
    }

    public graphql(
        query: string,
        variables: any,
        authorization: string | AuthToken
    ) {
        if (SXPConfig.debugLevel === "verbose") {
            console.log(query);
            console.log(variables);
            console.log(authorization);
        }

        return this.post("/graphql", { query: query, variables }, authorization)
            .then((response) => {
                if (response.data.errors) {
                    if (SXPConfig.debugLevel !== "silent") {
                        console.error(query);
                        console.error(variables);
                        console.error(authorization);
                        console.error(
                            JSON.stringify(response.data.errors, null, 4)
                        );
                    }

                    throw new Error(response.data.errors[0].message);
                }

                return response;
            })
            .catch((error) => {
                if (SXPConfig.debugLevel !== "silent") {
                    console.error(query);
                    console.error(variables);
                    console.error(authorization);
                    console.error(
                        JSON.stringify(error.response.data.errors, null, 4)
                    );
                }

                throw new Error(error.response.data.errors[0].message);
            });
    }

    public async login(data: { email: string; password: string }) {
        const config = {
            validateStatus: function (status: number) {
                return status < 300;
            },
            headers: {
                Authorization: "",
                "Content-Type": "application/json",
            },
        };

        const url = SXPConfig.apiOrigin + "/auth/login";

        return axios.post(url, data, config).then((response) => {
            return {
                ...response.data.data,
                expires: response.data.data.expires + new Date().getTime(),
            } as AuthToken;
        });
    }

    public async refresh(authToken: AuthToken) {
        const config = {
            validateStatus: function (status: number) {
                return status < 300;
            },
            headers: {
                Authorization: "",
                "Content-Type": "application/json",
            },
        };

        const data = {
            refresh_token: authToken.refresh_token,
            mode: "json",
        };

        const url = SXPConfig.apiOrigin + "/auth/refresh";

        return axios.post(url, data, config).then((response) => {
            return {
                ...response.data.data,
                expires: response.data.data.expires + new Date().getTime(),
            } as AuthToken;
        });
    }

    public async logout(authToken: AuthToken) {
        const config = {
            validateStatus: function (status: number) {
                return status < 300;
            },
            headers: {
                Authorization: "",
                "Content-Type": "application/json",
            },
        };

        const url = SXPConfig.apiOrigin + "/auth/logout";

        const data = {
            refresh_token: authToken.refresh_token,
        };

        return axios.post(url, data, config);
    }

    private createConfig(authorization: string | AuthToken, data?: any) {
        var config = {} as any;

        const token =
            typeof authorization === "string"
                ? authorization
                : authorization.access_token;

        if (token) {
            config.headers = {
                Authorization: this.getAuthorization(token),
                "Content-Type": "application/json",
            };
        } else {
            config.headers = {
                Authorization: "",
                "Content-Type": "application/json",
            };
        }

        if (data) {
            config.source = {
                source: data,
            };
        }

        return config;
    }

    private getAuthorization(authorization: string) {
        if (authorization.startsWith("Bearer ")) {
            return authorization;
        } else {
            return "Bearer " + authorization;
        }
    }
}
