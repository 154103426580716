import axios from "axios";
import { SXPConfig } from "..";

export default class SXPServicesStatic {
    public get(endpoint: string, authorization: string = "") {
        const config = this.createConfig(authorization);
        const url = SXPConfig.servicesOrigin + endpoint;

        return axios.get(url, config);
    }

    public post(endpoint: string, data: any, authorization: string = "") {
        const config = this.createConfig(authorization);
        const url = SXPConfig.servicesOrigin + endpoint;

        return axios.post(url, data, config);
    }

    public patch(endpoint: string, data: any, authorization: string = "") {
        const config = this.createConfig(authorization);
        const url = SXPConfig.servicesOrigin + endpoint;

        return axios.patch(url, data, config);
    }

    public delete(endpoint: string, authorization: string = "") {
        const config = this.createConfig(authorization);
        const url = SXPConfig.servicesOrigin + endpoint;

        return axios.delete(url, config);
    }

    private createConfig(authorization: string) {
        if (authorization) {
            return {
                headers: {
                    Authorization: this.getAuthorization(authorization),
                },
            };
        }

        return {};
    }

    private getAuthorization(authorization: string) {
        if (authorization.startsWith("Bearer ")) {
            return authorization;
        } else {
            return "Bearer " + authorization;
        }
    }
}
