import { Curriculum, Registration, arrayToObjectGeneric } from "../..";
import { SXPContext } from "../../SXPContext";
import { GenericQuery } from "./GenericQuery";

export type CourseFilter = {
    id?: string;
    languageCode?: string;
};

export class Course {
    private context: SXPContext;

    id: string;
    kind: "Course" = "Course";
    label: string;
    certification_label: string;
    skills_acquired: string;
    curriculum: string;
    course_category: string;
    child_equivalences: string[];
    parent_equivalences: string[];

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
        this.context = context;
    }

    public cloneDataOnly() {
        const { context, ...clone } = this as any;

        return clone;
    }

    public getCurriculum(): Curriculum | undefined {
        return this.context.curriculums.d[this.curriculum];
    }

    public getCourseCategory() {
        return this.context.courseCategories.d[this.course_category];
    }

    public getAssociatedRegistration(): Registration | undefined {
        const equivalentCourseIds = this.getEquivalenceIds();
        const registration = this.context.registrations.a.find((e) =>
            equivalentCourseIds.includes(e.course)
        );

        return registration;
    }

    public getChapter() {
        const courses =
            this.getParentEquivalence().getCurriculum()?.courses || [];
        const index = courses.findIndex((e) => e === this.id);

        return index + 1;
    }

    public hasCertification() {
        return this.certification_label.length > 0;
    }

    public getParentEquivalence() {
        return this.getParentEquivalenceInternal([]);
    }

    public getEquivalenceIds() {
        const equivalentIds = [] as string[];

        const notInspectedCourseIds = [this.id];

        while (notInspectedCourseIds.length > 0) {
            const id = notInspectedCourseIds.pop() as string;

            if (!equivalentIds.includes(id)) {
                equivalentIds.push(id);

                const course = this.context.courses.d[id];

                if (course) {
                    course.child_equivalences.forEach((e: any) => {
                        if (!equivalentIds.includes(e)) {
                            notInspectedCourseIds.push(e);
                        }
                    });
                }
            }
        }

        return equivalentIds;
    }

    public getRegistrationStatus() {
        const registration = this.getAssociatedRegistration();

        if (registration) {
            return registration.getStatus();
        }

        const isSkipped = this.context.participant.skipped_courses.some(
            (skippedCourseId) =>
                this.getEquivalenceIds().includes(skippedCourseId)
        );

        if (isSkipped) {
            return "skipped";
        }

        return "not-registered";
    }

    private getParentEquivalenceInternal(
        parentIds: string[]
    ): Course | undefined {
        if (this.parent_equivalences.length === 0) {
            return this;
        }

        if (this.parent_equivalences.includes(this.id)) {
            return this;
        }

        parentIds.push(this.id);

        const intersection = parentIds.filter((e) =>
            this.parent_equivalences.includes(e)
        );

        if (intersection.length > 0) {
            return this;
        }

        return this.context.courses.d[
            this.parent_equivalences[0]
        ].getParentEquivalenceInternal(parentIds);
    }
}

export class CourseQuery extends GenericQuery<Course, CourseFilter> {
    constructor(context: SXPContext) {
        super(context, Course, CourseQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.curriculum = data.curriculum?.id || "";
        data.course_category = data.course_category?.id || "";

        data.parent_equivalences = data.parent_equivalences.map((e: any) => {
            return e.related_courses_id.id;
        });

        if (data.parent_equivalences.includes(data.id)) {
            data.parent_equivalences = [data.id];
        } else {
            data.parent_equivalences =
                data.parent_equivalences.length > 0
                    ? data.parent_equivalences
                    : [];
        }

        data.child_equivalences = data.child_equivalences.map((e: any) => {
            return e.courses_id.id;
        });

        data.label = data.translations[0]?.label ?? "";
        data.skills_acquired = data.translations[0]?.skills_acquired ?? "";
        data.certification_label =
            data.translations[0]?.certification_label ?? "";

        delete data.translations;

        return data;
    }

    public async fillContext(filter: CourseFilter, override: boolean = false) {
        if (this.context.courses.a.length === 0 || override) {
            return this.fetchMany(filter).then((data) => {
                return (this.context.courses = arrayToObjectGeneric(
                    data,
                    "id"
                ));
            });
        }
    }

    public static getName() {
        return "CourseQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                curriculum {
                    id
                }
                course_category {
                    id
                }
                child_equivalences {
                    courses_id {
                        id
                    }
                }
                parent_equivalences {
                    related_courses_id {
                        id
                    }
                }
                translations(
                    filter: {
                        languages_code: { code: { _eq: $displayLanguage } }
                    }
                ) {
                    label
                    skills_acquired
                    certification_label
                }
            }
        `;
    }

    protected getQueryData(filter: CourseFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            data.filterArray.push(/* GraphQL */ `
                {
                    id: {
                        _eq: $id
                    }
                }
            `);
            data.filterParams.push("$id: String");
        }

        if (filter.languageCode) {
            data.filterArray.push(/* GraphQL */ `
                {
                    translations: {
                        languages_code: {
                            code: {
                                _eq: $languageCode
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$languageCode: String");
        }

        data.filterParams.push("$displayLanguage: String");

        data.variables = {
            displayLanguage: this.context.language,
            id: filter.id,
            languageCode: filter.languageCode,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "Course";
    }

    protected getGraphQLName(): string {
        return "courses";
    }

    protected getRestEndPoint(): string {
        return "/items/courses";
    }
}
