import { SXPContext } from "../../SXPContext";
import { GenericQuery } from "./GenericQuery";

export type ParticipantFilter = {
    id?: string;
    firstName?: string;
    lastName?: string;
    accountId?: string;
};

export class Participant {
    id: string;
    first_name: string;
    last_name: string;
    birth_date: string;
    email: string;
    phone: string;
    account: string;
    skipped_courses: string[];

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
    }
}

export class ParticipantQuery extends GenericQuery<
    Participant,
    ParticipantFilter
> {
    constructor(context: SXPContext) {
        super(context, Participant, ParticipantQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.account = data.account.id;

        data.skipped_courses = data.skipped_courses.map(
            (e: any) => e.courses_id.id
        );

        return data;
    }

    public async fillContext(
        filter: ParticipantFilter,
        override: boolean = false
    ) {
        if (this.context.participant === undefined || override) {
            return this.fetch(filter).then((data) => {
                return (this.context.participant = data);
            });
        }
    }

    public static getName() {
        return "ParticipantQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                first_name
                last_name
                birth_date
                email
                phone
                account {
                    id
                }
                skipped_courses {
                    courses_id {
                        id
                    }
                }
            }
        `;
    }

    protected getQueryData(filter: ParticipantFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            data.filterArray.push(/* GraphQL */ `
            {
                id: {
                    _eq: $id
                }
            }
        `);
            data.filterParams.push("$id: String");
        }

        if (filter.firstName) {
            data.filterArray.push(/* GraphQL */ `
            {
                first_name: {
                    _contains: $firstName
                }
            }
        `);
            data.filterParams.push("$firstName: String");
        }

        if (filter.lastName) {
            data.filterArray.push(/* GraphQL */ `
            {
                last_name: {
                    _contains: $lastName
                }
            }
        `);
            data.filterParams.push("$lastName: String");
        }

        if (filter.accountId) {
            data.filterArray.push(/* GraphQL */ `
            {
                account: {
                    id: {
                        _eq: $accountId
                    }
                }
            }
        `);
            data.filterParams.push("$accountId: String");
        }

        data.variables = {
            id: filter.id,
            firstName: filter.firstName,
            lastName: filter.lastName,
            accountId: filter.accountId,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "Participant";
    }

    protected getGraphQLName(): string {
        return "participants";
    }

    protected getRestEndPoint(): string {
        return "/items/participants";
    }
}
