import { Button } from "@mantine/core";
import { AccountQuery, UserQuery } from "@sxp-api-lib/index";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sxpctx } from "../..";
import { AppContext } from "../../AppContext";

export default function ParticipantSubscriberArea() {
    const context = useContext(AppContext);

    const [selectedAccountToken, setSelectedAccountToken] = useState("");

    const { id } = useParams();

    useEffect(() => {
        sxpctx
            .getQuery(AccountQuery)
            .fetch({ id: id })
            .then((account) => {
                if (!account) {
                    return;
                }

                const token = generateToken(32);
                const userData = { id: account.owner, token: token };

                sxpctx
                    .getQuery(UserQuery)
                    .update(userData)
                    .then(() => {
                        setSelectedAccountToken(token);
                    });
            });
    }, []);

    if (!selectedAccountToken) {
        return (
            <>
                <div>Loading</div>
            </>
        );
    }

    return (
        <>
            <Button
                onClick={() => {
                    window.location.hash = "/participant-manager";
                }}
            >
                Retour
            </Button>
            <div style={{ height: "20px" }}></div>
            <iframe
                src={
                    "https://cdn.studioxp.ca/subscriber-area/build/index.html?token=" +
                    selectedAccountToken
                }
                title="description"
                style={{
                    height: "calc(100% - 60px)",
                    width: "100%",
                }}
            ></iframe>
        </>
    );
}

function generateToken(n: number) {
    var chars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var token = "";
    for (var i = 0; i < n; i++) {
        token += chars[Math.floor(Math.random() * chars.length)];
    }
    return token;
}
