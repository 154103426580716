import React from "react";
import ReactDOM from "react-dom/client";
import { ModalsProvider } from "@mantine/modals";
import App from "./App";
import AppProvider from "./AppContext";
import "./i18n";
import {
    SXPConfig,
    SXPContext,
    addDateFormatter,
    sxpapi,
} from "@sxp-api-lib/index";
import i18next from "i18next";
import WeeklyCalendarManagerEditModal from "./pages/CalendarManager/components/WeeklyCalendarManagerEditModal";
import CampCalendarManagerEditModal from "./pages/CalendarManager/components/CampCalendarManagerEditModal";
import BucketGroupManagerEditModal from "./pages/BucketGroupManager/components/BucketGroupManagerEditModal";
import { QueryClient, QueryClientProvider } from "react-query";

export const sxpctx = new SXPContext();

SXPConfig.apiOrigin = process.env.REACT_APP_API_URL || "";
SXPConfig.debugLevel = "debug";

sxpctx.language = "fr-CA";

addDateFormatter(i18next);
i18next.changeLanguage("fr");

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ModalsProvider
                modals={{
                    BucketGroupManagerEditModal,
                    CampCalendarManagerEditModal,
                    WeeklyCalendarManagerEditModal,
                }}
            >
                <AppProvider>
                    <App />
                </AppProvider>
            </ModalsProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
