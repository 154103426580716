import { Box, Button, Group, PasswordInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
    UserQuery,
    delay,
    loadAuthTokenFromCookie,
    needTokenRefresh,
    saveAuthTokenToCookie,
    sxpapi,
} from "@sxp-api-lib/index";
import { AuthToken } from "@sxp-api-lib/api/sxpapi";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sxpctx } from ".";
import AppComponent from "./AppComponent";
import { AppContext } from "./AppContext";

var failedCount = 0;

function App() {
    const { t } = useTranslation();
    const context = useContext(AppContext);

    const [_null, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const form = useForm({
        initialValues: {
            email: "",
            password: "",
        },
    });

    var isRefreshing = false;

    var mount = false;
    useEffect(() => {
        if (!mount) {
            mount = true;

            loadAuthTokenFromCookie(sxpctx);

            if (!isRefreshing && sxpctx.authToken) {
                refreshToken(sxpctx.authToken).then(() => {
                    delay(100).then(() => {
                        setLoading(false);
                    });
                });
            } else {
                sxpctx.authToken = undefined;
                saveAuthTokenToCookie(sxpctx);
                updateUser();

                delay(100).then(() => {
                    setLoading(false);
                });
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener("hashchange", forceUpdate, false);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isRefreshing && sxpctx.authToken && needTokenRefresh(sxpctx)) {
                refreshToken(sxpctx.authToken);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    async function refreshToken(authToken: AuthToken) {
        isRefreshing = true;

        return sxpapi
            .refresh(authToken)
            .then((authToken) => {
                sxpctx.authToken = authToken;

                saveAuthTokenToCookie(sxpctx);
                updateUser();

                failedCount = 0;
                isRefreshing = false;
            })
            .catch(() => {
                failedCount++;

                if (failedCount > 5) {
                    if (sxpctx.authToken) {
                        sxpapi.logout(sxpctx.authToken).then(() => {
                            sxpctx.authToken = undefined;

                            saveAuthTokenToCookie(sxpctx);
                            updateUser();

                            isRefreshing = false;
                        });
                    }
                }
            });
    }

    function updateUser() {
        if (sxpctx.authToken && !sxpctx.user) {
            sxpctx
                .getQuery(UserQuery)
                .fillContext({ me: true })
                .then(() => {
                    context.data.user = sxpctx.user;
                    context.setState(context.data);
                });
        }

        if (!sxpctx.authToken && sxpctx.user) {
            sxpctx.user = undefined;

            context.data.user = undefined;
            context.setState(context.data);
        }
    }

    function onSubmitHandler(values: any) {
        setError(false);
        isRefreshing = true;

        sxpapi
            .login(values)
            .then((authToken) => {
                sxpctx.authToken = authToken;

                saveAuthTokenToCookie(sxpctx);
                updateUser();

                isRefreshing = false;
            })
            .catch(() => {
                console.log(error);
                setError(true);
                isRefreshing = false;
            });
    }

    if (loading) {
        return null;
    }

    if (!context.data.user) {
        return (
            <Box maw={300} mx="auto">
                <form onSubmit={form.onSubmit(onSubmitHandler)}>
                    <TextInput
                        label="Email"
                        placeholder="your@email.com"
                        required
                        {...form.getInputProps("email")}
                    />
                    <PasswordInput
                        placeholder="Password"
                        label="Password"
                        required
                        {...form.getInputProps("password")}
                        error={error}
                    />
                    <Group position="right" mt="md">
                        <Button type="submit">{t("app.connection")}</Button>
                    </Group>
                </form>
            </Box>
        );
    }

    return <AppComponent />;
}

export default App;
