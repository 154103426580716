import { i18n } from "i18next";
import { dateToLocalString } from "../helper/DateHelper";

export function addDateFormatter(i18n: i18n) {
    if (!i18n?.services?.formatter?.add) {
        return;
    }

    i18n.services.formatter.add("sxpTime", (value, lng, options) => {
        if (value.getMinutes() > 0) {
            return i18n.t("{{val, sxpTimeFull}}", { val: value });
        }

        switch (lng) {
            case "fr":
            case "fr-CA":
            case "fr-FR":
                return i18n
                    .t("{{val, datetime}}", {
                        val: value,
                        formatParams: {
                            val: {
                                hour: "numeric",
                            },
                        },
                    })
                    .replace(/ /g, "");
            case "en":
            case "en-CA":
            case "en-US":
            default: {
                return i18n
                    .t("{{val, datetime}}", {
                        val: value,
                        formatParams: {
                            val: {
                                hour: "numeric",
                            },
                        },
                    })
                    .replace(/\./g, "");
            }
        }
    });

    i18n.services.formatter.add("sxpTimeFull", (value, lng, options) => {
        switch (lng) {
            case "fr":
            case "fr-CA":
            case "fr-FR":
                return i18n
                    .t("{{val, datetime}}", {
                        val: value,
                        formatParams: {
                            val: {
                                hour: "numeric",
                                minute: "2-digit",
                            },
                        },
                    })
                    .replace(/ /g, "");
            case "en":
            case "en-CA":
            case "en-US":
            default: {
                return i18n
                    .t("{{val, datetime}}", {
                        val: value,
                        formatParams: {
                            val: {
                                hour: "numeric",
                                minute: "2-digit",
                            },
                        },
                    })
                    .replace(/\./g, "");
            }
        }
    });

    i18n.services.formatter.add("sxpTimeRaw", (value, lng, options) => {
        let hours = value.getHours().toString().padStart(2, "0");
        let minutes = value.getMinutes().toString().padStart(2, "0");

        return hours + ":" + minutes;
    });

    i18n.services.formatter.add("sxpDateOnly", (value, lng, options) => {
        return i18n.t("{{val, datetime}}", {
            val: value,
            formatParams: {
                val: {
                    day: "numeric",
                },
            },
        });
    });

    i18n.services.formatter.add("sxpDate", (value, lng, options) => {
        return i18n.t("{{val, datetime}}", {
            val: value,
            formatParams: {
                val: {
                    month: "long",
                    day: "numeric",
                },
            },
        });
    });

    i18n.services.formatter.add("sxpDateRaw", (value, lng, options) => {
        return dateToLocalString(value).split("T")[0];
    });

    i18n.services.formatter.add("sxpDateShort", (value, lng, options) => {
        return i18n.t("{{val, datetime}}", {
            val: value,
            formatParams: {
                val: {
                    month: "short",
                    day: "numeric",
                },
            },
        });
    });

    i18n.services.formatter.add("sxpDateYear", (value, lng, options) => {
        return i18n.t("{{val, datetime}}", {
            val: value,
            formatParams: {
                val: {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                },
            },
        });
    });

    i18n.services.formatter.add("sxpDateYearShort", (value, lng, options) => {
        return i18n.t("{{val, datetime}}", {
            val: value,
            formatParams: {
                val: {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                },
            },
        });
    });

    i18n.services.formatter.add("sxpWeekDay", (value, lng, options) => {
        return i18n.t("{{val, datetime}}", {
            val: value,
            formatParams: {
                val: {
                    weekday: "long",
                },
            },
        });
    });

    i18n.services.formatter.add("sxpWeekDayShort", (value, lng, options) => {
        return i18n.t("{{val, datetime}}", {
            val: value,
            formatParams: {
                val: {
                    weekday: "short",
                },
            },
        });
    });
}
