import {
    CalendarQuery,
    changeDateTimeZone,
    createDateWithTimeZone,
    dateToLocalString,
    getNow,
} from "../..";
import { SXPContext } from "../../SXPContext";
import { Calendar } from "./CalendarQuery";
import { GenericQuery } from "./GenericQuery";

export type BucketGroupFilter = {
    id?: string | string[];
    name?: string;
    calendarName?: string;
    languageCode?: string;
    region?: string;
    weekday?: string;
    startTimeHour?: string;
    startTimeMinute?: string;
    endTimeHour?: string;
    endTimeMinute?: string;
    firstDateGreaterEquals?: string;
    firstDateLesserEquals?: string;
    lastDateGreaterEquals?: string;
    lastDateLesserEquals?: string;
    stockMin?: number;
    stockMax?: number;
    stockOverride?: "stock_high" | "stock_low" | "out_of_stock";
    locked?: boolean;
    format?: string;
    locationId?: string;
    curriculumId?: string | string[];
    shopCategoryGroupId?: string;
    shopCategoryId?: string | string[] | null;
    isValid?: boolean;
};

export class BucketGroup {
    private context: SXPContext;

    id: string;
    kind: "BucketGroup" = "BucketGroup";
    name: string;
    location: string;
    calendar: Calendar;
    stock: number;
    stock_override: "stock_high" | "stock_low" | "out_of_stock";
    locked: boolean;
    curriculums: string[];
    shop_categories: string[];

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
        this.context = context;

        if (data.calendar) {
            this.calendar = new Calendar(data.calendar, this.context);
        }
    }

    public cloneDataOnly() {
        const { context, ...clone } = this as any;

        clone.calendar = clone.calendar.cloneDataOnly();

        return clone;
    }

    getLocation() {
        return this.context.locations.d[this.location];
    }

    getCurriculums() {
        return this.curriculums
            .map((e) => this.context.curriculums.d[e])
            .filter((e) => e);
    }

    getShopCategories() {
        return this.shop_categories
            .map((e) => this.context.shopCategories.d[e])
            .filter((e) => e);
    }

    public getStatus() {
        const events = this.calendar.events;
        const timezone = this.calendar.getRegion().timezone;

        const firstEvent = createDateWithTimeZone(
            new Date(events[0].start),
            timezone
        );
        const lastEvent = createDateWithTimeZone(
            new Date(events[events.length - 1].end),
            timezone
        );

        if (getNow().getTime() < firstEvent.getTime()) {
            return "to-come";
        }

        if (getNow().getTime() > lastEvent.getTime()) {
            return "completed";
        }

        return "in-progress";
    }

    public getRegistrationDeadline() {
        const firstDate = this.calendar.getStartDate(0, "America/Montreal");
        const deadline = new Date(firstDate);

        switch (this.calendar.format) {
            case "camp":
                // first date is always monday, deadline is previous Wednesday
                deadline.setDate(deadline.getDate() - 5);
                break;
            case "weekly":
                if (deadline.getDay() === 0) {
                    // previous monday if Sunday
                    deadline.setDate(deadline.getDate() - 6);
                } else if (deadline.getDay() === 6) {
                    // previous monday if Saturday
                    deadline.setDate(deadline.getDate() - 5);
                } else {
                    // previous friday if it's a week day
                    deadline.setDate(
                        deadline.getDate() - deadline.getDay() - 2
                    );
                }
                break;
            default:
                // should never happen
                deadline.setDate(deadline.getDate() - 7);
        }

        deadline.setHours(9, 0, 0, 0);

        return dateToLocalString(deadline);
    }

    public getStockState() {
        if (this.locked) {
            return "out-of-stock";
        }

        if (this.stock === 0 || this.stock_override === "out_of_stock") {
            return "out-of-stock";
        }

        if (this.stock <= 5 || this.stock_override === "stock_low") {
            return "stock-low";
        }

        return "stock-high";
    }

    public isOpenByDeadline() {
        const now = dateToLocalString(
            changeDateTimeZone(getNow(), "America/Montreal")
        );
        const deadline = this.getRegistrationDeadline();

        return now.localeCompare(deadline) <= 0;
    }

    public isOpenForRegistration() {
        return (
            this.isOpenByDeadline() && this.getStockState() !== "out-of-stock"
        );
    }
}

export class BucketGroupQuery extends GenericQuery<
    BucketGroup,
    BucketGroupFilter
> {
    constructor(context: SXPContext) {
        super(context, BucketGroup, BucketGroupQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.location = data.location.id;
        data.curriculums = data.curriculums.map(
            (e: any) => e.curriculums_id.id
        );
        data.shop_categories = data.shop_categories.map(
            (e: any) => e.shop_categories_id.id
        );
        data.stock = data.stock === null ? 9999 : data.stock;
        data.stock_override = data.stock_override || "stock_high";

        data.calendar = CalendarQuery.prepareInstanceFromGraphQL(data.calendar);

        return data;
    }

    public async fillContext(
        filter: BucketGroupFilter,
        override: boolean = false
    ) {
        throw new Error("No context for bucket groups");
    }

    public static getName() {
        return "BucketGroupQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                name
                location {
                    id
                }
                stock
                stock_override
                locked
                curriculums {
                    curriculums_id {
                        id
                    }
                }
                shop_categories {
                    shop_categories_id {
                        id
                    }
                }
                calendar {
                    id
                    name
                    format {
                        id
                    }
                    events(sort: ["start"]) {
                        start
                        end
                    }
                    region {
                        id
                    }
                    suffix
                    extra
                }
            }
        `;
    }

    protected getQueryData(filter: BucketGroupFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            if (Array.isArray(filter.id)) {
                data.filterArray.push(/* GraphQL */ `
                    { 
                        id: { 
                            _in: $id
                        } 
                    }
                `);
                data.filterParams.push("$id: [String]");
            } else {
                data.filterArray.push(/* GraphQL */ `
                    {
                        id: {
                            _eq: $id
                        }
                    }
                `);
                data.filterParams.push("$id: String");
            }
        }

        if (filter.name) {
            const names = filter.name.split("*").filter((e) => e);

            const parts = names
                .map((e, index) => {
                    return /* GraphQL */ `
                        {
                            name: {
                                _contains: $name${index}
                            }
                        }                
                `;
                })
                .join(",");

            data.filterArray.push(/* GraphQL */ `
                {
                    _and: [
                        ${parts}
                    ]
                }
            `);

            names.forEach((e, index) => {
                data.filterParams.push("$name" + index + ": String");
            });
        }

        if (filter.calendarName) {
            const names = filter.calendarName.split("*").filter((e) => e);

            const parts = names
                .map((e, index) => {
                    return /* GraphQL */ `
                        {
                            calendar: {
                                name: {
                                    _contains: $calendarName${index}
                                }
                            }
                        }                
                `;
                })
                .join(",");

            data.filterArray.push(/* GraphQL */ `
                {
                    _and: [
                        ${parts}
                    ]
                }
            `);

            names.forEach((e, index) => {
                data.filterParams.push("$calendarName" + index + ": String");
            });
        }

        if (filter.languageCode) {
            data.filterArray.push(/* GraphQL */ `
                {
                    shop_categories: {
                        shop_categories_id: {
                            language: {
                                code: {
                                    _eq: $languageCode
                                }
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$languageCode: String");
        }

        if (filter.region) {
            data.filterArray.push(/* GraphQL */ `
            {
                calendar: {
                    region: {
                        id: {
                            _eq: $region
                        }
                    }
                }
            }
        `);
            data.filterParams.push("$region: String");
        }

        if (filter.weekday) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_first_event_start_func:{
                            weekday: {
                                _eq: $weekday
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$weekday: GraphQLStringOrFloat");
        }

        if (filter.startTimeHour) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_first_event_start_func:{
                            hour: {
                                _eq: $startTimeHour
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$startTimeHour: GraphQLStringOrFloat");
        }

        if (filter.startTimeMinute) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_first_event_start_func:{
                            minute: {
                                _eq: $startTimeMinute
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$startTimeMinute: GraphQLStringOrFloat");
        }

        if (filter.endTimeHour) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_last_event_end_func:{
                            hour: {
                                _eq: $endTimeHour
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$endTimeHour: GraphQLStringOrFloat");
        }

        if (filter.endTimeMinute) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_last_event_end_func:{
                            minute: {
                                _eq: $endTimeMinute
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$endTimeMinute: GraphQLStringOrFloat");
        }

        if (filter.firstDateGreaterEquals) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_first_event_start:{
                            _gte: $firstDateGreaterEquals
                        }
                    }
                }
            `);
            data.filterParams.push("$firstDateGreaterEquals: String");
        }

        if (filter.firstDateLesserEquals) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_first_event_start:{
                            _lte: $firstDateLesserEquals
                        }
                    }
                }
            `);
            data.filterParams.push("$firstDateLesserEquals: String");
        }

        if (filter.lastDateGreaterEquals) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_last_event_end: {
                            _gte: $lastDateGreaterEquals
                        }
                    }
                }
            `);
            data.filterParams.push("$lastDateGreaterEquals: String");
        }

        if (filter.lastDateLesserEquals) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        calc_last_event_end: {
                            _lte: $lastDateLesserEquals
                        }
                    }
                }
            `);
            data.filterParams.push("$lastDateLesserEquals: String");
        }

        if (filter.stockMin) {
            data.filterArray.push(/* GraphQL */ `
                {
                    stock: {
                            _gte: $stockMin
                        }
                }
            `);
            data.filterParams.push("$stockMin: GraphQLStringOrFloat");
        }

        if (filter.stockMax) {
            data.filterArray.push(/* GraphQL */ `
                {
                    stock: {
                            _lte: $stockMax
                        }
                }
            `);
            data.filterParams.push("$stockMax: GraphQLStringOrFloat");
        }

        if (filter.stockOverride) {
            data.filterArray.push(/* GraphQL */ `
                {
                    stock_override: {
                            _eq: $stockOverride
                        }
                }
            `);
            data.filterParams.push("$stockOverride: String");
        }

        if (filter.locked !== undefined) {
            data.filterArray.push(/* GraphQL */ `
                {
                    locked: {
                        _eq: $locked
                    }
                }
            `);
            data.filterParams.push("$locked: Boolean");
        }

        if (filter.format) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        format: {
                            id: {
                                _eq: $format
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$format: String");
        }

        if (filter.shopCategoryGroupId) {
            data.filterArray.push(/* GraphQL */ `
                {
                    shop_categories: {
                        shop_categories_id: {
                            shop_categories_group: {
                                id: {
                                    _eq: $shopCategoryGroupId
                                }
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$shopCategoryGroupId: String");
        }

        if (filter.shopCategoryId !== undefined) {
            if (Array.isArray(filter.shopCategoryId)) {
                data.filterArray.push(/* GraphQL */ `
                    { 
                        shop_categories: {
                            shop_categories_id: {
                                id: {
                                    _in: $shopCategoryId
                                }
                            }
                        }
                    }
                `);
                data.filterParams.push("$shopCategoryId: [String]");
            } else if (filter.shopCategoryId === null) {
                data.filterArray.push(/* GraphQL */ ` 
                    { 
                        shop_categories_func:{
                            count:{
                                _eq:0
                            }
                        }
                    },  
                `);
            } else {
                data.filterArray.push(/* GraphQL */ `
                    {
                        shop_categories: {
                            shop_categories_id: {
                                id: {
                                    _eq: $shopCategoryId
                                }
                            }
                        }
                    }
                `);
                data.filterParams.push("$shopCategoryId: String");
            }
        }

        if (filter.locationId) {
            data.filterArray.push(/* GraphQL */ `
                {
                    location: {
                        id: {
                            _eq: $locationId
                        }
                    }
                }
            `);
            data.filterParams.push("$locationId: String");
        }

        if (filter.curriculumId) {
            if (Array.isArray(filter.curriculumId)) {
                data.filterArray.push(/* GraphQL */ `
                    {
                        curriculums: {
                            curriculums_id: {
                                id: {
                                    _in: $curriculumId
                                }
                            }
                        }
                    }
                `);
                data.filterParams.push("$curriculumId: [String]");
            } else {
                data.filterArray.push(/* GraphQL */ `
                    {
                        curriculums: {
                            curriculums_id: {
                                id: {
                                    _eq: $curriculumId
                                }
                            }
                        }
                    }
                `);
                data.filterParams.push("$curriculumId: String");
            }
        }

        if (filter.isValid) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calendar: {
                        format: {
                            id: {
                                _nnull: true
                            }
                        }
                    }
                }
            `);
        }

        const calendarNames: any = {};

        if (filter.calendarName) {
            filter.calendarName
                .split("*")
                .filter((e) => e)
                .forEach((e, index) => {
                    calendarNames["calendarName" + index] = e;
                });
        }

        const names: any = {};

        if (filter.name) {
            filter.name
                .split("*")
                .filter((e) => e)
                .forEach((e, index) => {
                    names["name" + index] = e;
                });
        }

        data.variables = {
            id: filter.id,
            languageCode: filter.languageCode,
            region: filter.region,
            weekday: filter.weekday,
            startTimeHour: filter.startTimeHour,
            startTimeMinute: filter.startTimeMinute,
            endTimeHour: filter.endTimeHour,
            endTimeMinute: filter.endTimeMinute,
            firstDateGreaterEquals: filter.firstDateGreaterEquals,
            firstDateLesserEquals: filter.firstDateLesserEquals,
            lastDateGreaterEquals: filter.lastDateGreaterEquals,
            lastDateLesserEquals: filter.lastDateLesserEquals,
            stockMin: filter.stockMin,
            stockMax: filter.stockMax,
            stockOverride: filter.stockOverride,
            locked: filter.locked,
            format: filter.format,
            curriculumId: filter.curriculumId,
            locationId: filter.locationId,
            shopCategoryGroupId: filter.shopCategoryGroupId,
            shopCategoryId: filter.shopCategoryId,
            ...names,
            ...calendarNames,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "BucketGroup";
    }

    protected getGraphQLName(): string {
        return "bucket_groups";
    }

    protected getRestEndPoint(): string {
        return "/items/bucket_groups";
    }
}
