import { SXPConfig } from "..";

export function getNow() {
    if (SXPConfig.now) {
        return SXPConfig.now;
    } else {
        return new Date();
    }
}

export function createDateWithTimeZone(date: Date, timeZone: string) {
    var shiftedDate = new Date(
        date.toLocaleString("en-US", {
            timeZone,
        })
    );

    var diff = shiftedDate.getTime() - date.getTime();

    return new Date(date.getTime() - diff);
}

export function changeDateTimeZone(date: Date, timeZone: string) {
    var shiftedDate = new Date(
        date.toLocaleString("en-US", {
            timeZone,
        })
    );

    var diff = date.getTime() - shiftedDate.getTime();

    return new Date(date.getTime() - diff);
}

export function dateToLocalString(date: Date) {
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);

    return newDate.toISOString();
}
