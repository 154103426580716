import { SXPContext } from "../../SXPContext";
import { arrayToObjectGeneric } from "../../helper/ArrayHelper";
import { GenericQuery } from "./GenericQuery";

export type RegionFilter = {
    id?: string | string[];
};

export class Region {
    id: string;
    kind: "Region" = "Region";
    name: string;
    short_name: string;
    timezone: string;

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
    }
}

export class RegionQuery extends GenericQuery<Region, RegionFilter> {
    constructor(context: SXPContext) {
        super(context, Region, RegionQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        return data;
    }

    public async fillContext(filter: RegionFilter, override: boolean = false) {
        if (this.context.regions.a.length === 0 || override) {
            return this.fetchMany(filter).then((data) => {
                return (this.context.regions = arrayToObjectGeneric(
                    data,
                    "id"
                ));
            });
        }
    }

    public static getName() {
        return "RegionQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                name
                short_name
                timezone
            }
        `;
    }

    protected getQueryData(filter: RegionFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            if (Array.isArray(filter.id)) {
                data.filterArray.push(/* GraphQL */ `
                    { 
                        id: { 
                            _in: $id
                        } 
                    }
                `);
                data.filterParams.push("$id: [String]");
            } else {
                data.filterArray.push(/* GraphQL */ `
                    { 
                        id: { 
                            _eq: $id
                        } 
                    }
                `);
                data.filterParams.push("$id: String");
            }
        }

        data.variables = {
            id: filter.id,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "Region";
    }

    protected getGraphQLName(): string {
        return "regions";
    }

    protected getRestEndPoint(): string {
        return "/items/regions";
    }
}
