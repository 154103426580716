import SXPConfigStatic from "./SXPConfig";
import sxpapiStatic from "./api/sxpapi";
import SXPServicesStatic from "./api/SXPServices";

export * from "./db/api-v2/AccountQuery";
export * from "./db/api-v2/BucketGroupQuery";
export * from "./db/api-v2/CalendarQuery";
export * from "./db/api-v2/CalendarFormatQuery";
export * from "./db/api-v2/CourseQuery";
export * from "./db/api-v2/CourseCategoryQuery";
export * from "./db/api-v2/CurriculumQuery";
export * from "./db/api-v2/LanguageQuery";
export * from "./db/api-v2/LocationQuery";
export * from "./db/api-v2/OrderQuery";
export * from "./db/api-v2/ParticipantQuery";
export * from "./db/api-v2/RegionQuery";
export * from "./db/api-v2/RegistrationQuery";
export * from "./db/api-v2/ShopCategoryQuery";
export * from "./db/api-v2/TemplateQuery";
export * from "./db/api-v2/UserQuery";
export * from "./db/api-v2/WebsiteConfigurationQuery";

export * from "./SXPContext";

export * from "./helper/ArrayHelper";
export * from "./helper/AuthenticationHelper";
export * from "./helper/BucketGroupHelper";
export * from "./helper/CalendarHelper";
export * from "./helper/DateHelper";
export * from "./helper/LanguageHelper";
export * from "./helper/RegistrationHelper";
export * from "./helper/TimeHelper";

export * from "./formatter/TranslationLoader";
export * from "./formatter/MoneyFormatter";
export * from "./formatter/DateFormatter";
export * from "./formatter/CalendarFormatter";

export * from "./db/api/RealGroupsQueries";

export const SXPConfig = new SXPConfigStatic();
export const sxpapi = new sxpapiStatic();
export const SXPServices = new SXPServicesStatic();
