import { sxpapi } from "../..";
import { SXPContext } from "../../SXPContext";
import { getAuthorization } from "../../helper/AuthenticationHelper";

export type UserFilter = {
    id?: string;
    email?: string;
    me?: boolean;
};

export class User {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    email_notifications: boolean;
    role: string;
    avatar: string;

    getWordpressId() {
        const wid = parseInt(
            this.email.replace("client_", "").replace("@studioxp.ca", "")
        );

        if (isNaN(wid)) {
            return "";
        }

        return wid;
    }
}

export class UserQuery {
    protected context: SXPContext;

    public constructor(context: SXPContext) {
        this.context = context;
    }

    public async fillContext(filter: UserFilter, override: boolean = false) {
        if (this.context.user === undefined || override) {
            return this.fetch(filter).then((data) => {
                return (this.context.user = data);
            });
        }
    }

    public static getName() {
        return "UserQuery";
    }

    public async fetchMany(filter: UserFilter) {
        if (filter.id) {
            return sxpapi
                .get("/users/" + filter.id, this.getToken())
                .then((response) => {
                    return [response.data.data] as User[];
                });
        }

        if (filter.email) {
            return sxpapi
                .get("/users?filter[email]=" + filter.email, this.getToken())
                .then((response) => {
                    return response.data.data as User[];
                });
        }

        if (filter.me) {
            return sxpapi.get("/users/me", this.getToken()).then((response) => {
                return [response.data.data] as User[];
            });
        }

        return sxpapi.get("/users", this.getToken()).then((response) => {
            return response.data.data as User[];
        });
    }

    public async fetch(filter: UserFilter) {
        return this.fetchMany(filter).then((users) => {
            if (users.length === 0) {
                return undefined;
            }

            return users[0];
        });
    }

    public async create(payload: any) {
        return sxpapi
            .post("/users", payload, this.getToken())
            .then((response) => {
                if (response.data?.data?.id) {
                    return response.data.data.id as string;
                } else {
                    throw new Error("Cannot create user");
                }
            });
    }

    public async update(payload: any) {
        return sxpapi
            .patch("/users/" + payload.id, payload, this.getToken())
            .then((response) => {
                if (response.data?.data?.id) {
                    return response.data.data.id as string;
                } else {
                    throw new Error("Cannot update user");
                }
            });
    }

    private getToken() {
        return getAuthorization(this.context);
    }
}
