import {
    faCampground,
    faChartLine,
    faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    AppShell,
    Burger,
    Header,
    MediaQuery,
    NavLink,
    Navbar,
} from "@mantine/core";
import {
    CalendarFormatQuery,
    CourseQuery,
    CurriculumQuery,
    LocationQuery,
    RegionQuery,
    ShopCategoryQuery,
    saveAuthTokenToCookie,
    sxpapi,
} from "@sxp-api-lib/index";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { sxpctx } from ".";
import { AppContext } from "./AppContext";
import { isUserRole } from "./helpers/UserHelper";
import BucketGroupManager from "./pages/BucketGroupManager";
import CampCalendarManager from "./pages/CalendarManager";
import ParticipantInspector from "./pages/ParticipantInspector";
import ParticipantManager from "./pages/ParticipantManager";
import ParticipantSubscriberArea from "./pages/ParticipantSubscriberArea";
import Test from "./pages/Test";

function AppComponent() {
    const { t } = useTranslation();
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        Promise.all([
            sxpctx.getQuery(ShopCategoryQuery).fillContext({}),
            sxpctx.getQuery(LocationQuery).fillContext({}),
            sxpctx.getQuery(CurriculumQuery).fillContext({}),
            sxpctx.getQuery(CourseQuery).fillContext({}),
            sxpctx.getQuery(RegionQuery).fillContext({}),
            sxpctx.getQuery(CalendarFormatQuery).fillContext({}),
        ]).then(() => {
            setLoading(false);
        });
    }, []);

    function onLogoutHandler() {
        if (sxpctx.authToken) {
            sxpapi.logout(sxpctx.authToken).then(() => {
                sxpctx.authToken = undefined;
                sxpctx.user = undefined;

                saveAuthTokenToCookie(sxpctx);

                context.data.user = undefined;
                context.setState(context.data);
            });
        } else {
            sxpctx.authToken = undefined;
            sxpctx.user = undefined;

            saveAuthTokenToCookie(sxpctx);

            context.data.user = undefined;
            context.setState(context.data);
        }
    }

    if (loading) {
        return null;
    }

    const router = createHashRouter([
        {
            path: "/",
            element: <div>Dashboard</div>,
        },
        {
            path: "/test",
            element: <Test />,
        },
        {
            path: "/calendar-manager",
            element: <CampCalendarManager />,
        },
        {
            path: "/bucket-group-manager",
            element: <BucketGroupManager />,
        },
        {
            path: "/participant-subscriber-area/:id",
            element: <ParticipantSubscriberArea />,
        },
        {
            path: "/participant-manager",
            element: <ParticipantManager />,
        },
        {
            path: "/participant-inspector/:id",
            element: <ParticipantInspector />,
        },
    ]);

    return (
        <div>
            <AppShell
                header={
                    <Header height={{ base: 50, md: 70 }} p="md">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <MediaQuery
                                largerThan="sm"
                                styles={{ display: "none" }}
                            >
                                <Burger
                                    opened={menuOpen}
                                    onClick={() => setMenuOpen((o) => !o)}
                                    size="sm"
                                    mr="xl"
                                />
                            </MediaQuery>

                            <div>Studio XP</div>
                        </div>
                    </Header>
                }
                navbar={
                    <Navbar
                        p="md"
                        hiddenBreakpoint="sm"
                        hidden={!menuOpen}
                        width={{ sm: 200, lg: 300 }}
                    >
                        <NavLink
                            label={t("app.title")}
                            icon={<FontAwesomeIcon icon={faChartLine} />}
                            component="a"
                            href="#/"
                            active={"#/" === document.location.hash}
                        ></NavLink>
                        {(isUserRole(sxpctx.user, "admin") ||
                            isUserRole(sxpctx.user, "tester")) && (
                            <NavLink
                                label={t("participantManager.title")}
                                component="a"
                                href="#/participant-manager"
                                icon={<FontAwesomeIcon icon={faCampground} />}
                                active={
                                    "#/participant-manager" ===
                                    document.location.hash
                                }
                            />
                        )}
                        {isUserRole(sxpctx.user, "admin") && (
                            <NavLink
                                label={t("calendarManager.title")}
                                component="a"
                                href="#/calendar-manager"
                                icon={<FontAwesomeIcon icon={faCampground} />}
                                active={
                                    "#/calendar-manager" ===
                                    document.location.hash
                                }
                            />
                        )}
                        {isUserRole(sxpctx.user, "admin") && (
                            <NavLink
                                label={t("bucketGroupManager.title")}
                                component="a"
                                href="#/bucket-group-manager"
                                icon={<FontAwesomeIcon icon={faCampground} />}
                                active={
                                    "#/bucket-group-manager" ===
                                    document.location.hash
                                }
                            />
                        )}
                        <NavLink
                            label={t("menu.logout")}
                            icon={<FontAwesomeIcon icon={faRightFromBracket} />}
                            onClick={onLogoutHandler}
                        ></NavLink>
                    </Navbar>
                }
            >
                <RouterProvider router={router} />
            </AppShell>
        </div>
    );
}

export default AppComponent;
