export type ArrayDict<TItem> = {
    d: { [key: string]: TItem };
    a: TItem[];
};

export function arrayToObject<TItem extends { id: string }>(
    items: TItem[]
): ArrayDict<TItem> {
    const obj: ArrayDict<TItem> = {
        d: {},
        a: items,
    };

    items.forEach((item) => {
        obj.d[item.id] = item;
    });

    return obj;
}

export function arrayToObjectGeneric<TItem>(
    items: TItem[],
    idKey: keyof TItem
): ArrayDict<TItem> {
    const obj: ArrayDict<TItem> = {
        d: {},
        a: items,
    };

    items.forEach((item) => {
        const id = item[idKey] as string;
        obj.d[id] = item;
    });

    return obj;
}

export function onlyUnique<T>(value: T, index: number, array: T[]) {
    return array.indexOf(value) === index;
}

export function onlyUniqueFn<T>(fn: (o: T) => any) {
    return (value: T, index: number, array: T[]) =>
        index === array.findIndex((e) => fn(value) === fn(e));
}

export function toggleValueFromArray<T>(array: T[], value: T): T[] {
    const index = array.indexOf(value);

    if (index !== -1) {
        array.splice(index, 1);
    } else {
        array.push(value);
    }

    return array;
}
