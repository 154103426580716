import {
    Box,
    Button,
    Checkbox,
    Group,
    LoadingOverlay,
    MultiSelect,
    NumberInput,
    Select,
    TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import {
    BucketGroup,
    BucketGroupQuery,
    Calendar,
    CalendarQuery,
    onlyUniqueFn,
} from "@sxp-api-lib/index";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { sxpctx } from "../../../..";
import { AppContext } from "../../../../AppContext";
import { updateBucketGroupName } from "../../../../helpers/BucketGroupHelper";
import i18n from "../../../../i18n";

type FormValuesType = {
    id: string;
    name: string;
    calendar: string;
    location: string;
    curriculums: string[];
    shopCategories: string[];
    stock: number;
    stockOverride: string;
    locked: boolean;
};

const defaultFormValues = {
    id: "",
    name: "",
    calendar: "",
    location: "",
    curriculums: [],
    shopCategories: [],
    stock: 0,
    stockOverride: "out_of_stock",
    locked: true,
} as FormValuesType;

type BucketGroupManagerEditModalProps = {
    id?: string;
};

export default function BucketGroupManagerEditModal(
    props: ContextModalProps<BucketGroupManagerEditModalProps>
) {
    const { t } = useTranslation();
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [searchCalendar, setSearchCalendar] = useState("");
    const [selectedCalendarData, setSelectedCalendarData] = useState({
        value: "",
        label: "",
    });

    const form = useForm({ initialValues: defaultFormValues });

    const queryClient = useQueryClient();

    const { mutate } = useMutation({
        mutationFn: async (values: FormValuesType) => {
            setSaving(true);

            const payload = createPayload(values, calendars.data || []);

            let id = "";
            if (payload.id) {
                id = await sxpctx.getQuery(BucketGroupQuery).update(payload);
            } else {
                id = await sxpctx.getQuery(BucketGroupQuery).create(payload);
            }

            // updateBucketGroupName(id);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["bucketGroups"] });
            setSaving(false);
            props.context.closeModal(props.id);
        },
    });

    const calendars = useQuery({
        queryKey: ["calendars", searchCalendar],
        queryFn: () =>
            sxpctx.getQuery(CalendarQuery).fetchMany(
                {
                    name: searchCalendar || undefined,
                },
                10,
                "name"
            ),
        keepPreviousData: true,
    });

    useEffect(() => {
        const promises = [];

        if (props.innerProps.id) {
            promises.push(
                sxpctx
                    .getQuery(BucketGroupQuery)
                    .fetch({ id: props.innerProps.id })
                    .then((bucketGroup) => {
                        form.setValues(createFormValues(bucketGroup));
                        setSelectedCalendarData({
                            value: bucketGroup.calendar.id,
                            label: bucketGroup.calendar.name,
                        });
                    })
            );
        }

        Promise.all(promises)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [props.context.openModal]);

    const stockOverrideStateData = Object.entries(
        i18n.getResourceBundle(i18n.language, "").common.stockOverrideState
    ).map((e) => ({ value: e[0], label: e[1] as string }));

    return (
        <Box pos="relative">
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <form onSubmit={form.onSubmit((values) => mutate(values))}>
                <TextInput
                    label={t("common.name")}
                    {...form.getInputProps("name")}
                />
                <Select
                    label={t("common.calendar")}
                    data={
                        calendars.data
                            ?.map((e) => ({
                                value: e.id,
                                label: e.name,
                            }))
                            .concat([selectedCalendarData])
                            .filter(onlyUniqueFn((e) => e.value))
                            ?.sort((a, b) => a.label.localeCompare(b.label)) ||
                        []
                    }
                    dropdownPosition="bottom"
                    withinPortal={true}
                    searchable
                    filter={(value, item) =>
                        value
                            .trim()
                            .split(" ")
                            .every((e) =>
                                item?.label
                                    ?.trim()
                                    ?.toLocaleLowerCase()
                                    ?.includes(e.toLocaleLowerCase())
                            )
                    }
                    onSearchChange={(value) => {
                        setSearchCalendar(value);
                        queryClient.invalidateQueries(["calendar", value]);
                    }}
                    {...form.getInputProps("calendar")}
                />
                <Select
                    label={t("common.location")}
                    data={createLocationSelectData()}
                    dropdownPosition="bottom"
                    withinPortal={true}
                    searchable
                    {...form.getInputProps("location")}
                />
                <MultiSelect
                    label={t("common.curriculum")}
                    data={createCurriculumSelectData()}
                    dropdownPosition="bottom"
                    withinPortal={true}
                    {...form.getInputProps("curriculums")}
                />
                <MultiSelect
                    label={t("common.shopCategory")}
                    data={createShopCategorySelectData()}
                    dropdownPosition="bottom"
                    withinPortal={true}
                    {...form.getInputProps("shopCategories")}
                />
                <NumberInput
                    label={t("common.stock")}
                    {...form.getInputProps("stock")}
                />
                <Select
                    label={t("common.stockOverride")}
                    data={stockOverrideStateData}
                    dropdownPosition="bottom"
                    withinPortal={true}
                    {...form.getInputProps("stockOverride")}
                />
                <Group mt="xs">
                    <Checkbox
                        label={t("common.locked")}
                        {...form.getInputProps("locked", { type: "checkbox" })}
                    />
                </Group>
                <Group position="right" mt="md">
                    <Button type="submit">{t("calendarManager.save")}</Button>
                </Group>
            </form>
        </Box>
    );
}

function createFormValues(bucketGroup: BucketGroup) {
    return {
        id: bucketGroup.id,
        name: bucketGroup.name,
        calendar: bucketGroup.calendar.id,
        location: bucketGroup.location,
        curriculums: bucketGroup.curriculums,
        shopCategories: bucketGroup.shop_categories,
        stock: bucketGroup.stock,
        stockOverride: bucketGroup.stock_override,
        locked: bucketGroup.locked,
    } as FormValuesType;
}

function createPayload(obj: FormValuesType, calendars: Calendar[]) {
    return {
        id: obj.id,
        name: obj.name,
        calendar: obj.calendar,
        location: obj.location,
        curriculums: obj.curriculums.map((e) => ({ curriculums_id: e })),
        shop_categories: obj.shopCategories.map((e) => ({
            shop_categories_id: e,
        })),
        stock: obj.stock,
        stock_override: obj.stockOverride,
        locked: obj.locked,
    } as any;
}

function createLocationSelectData() {
    return sxpctx.locations.a
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((e) => ({ value: e.id, label: e.label }));
}

function createCurriculumSelectData() {
    return sxpctx.curriculums.a
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((e) => ({ value: e.id, label: e.label }));
}

function createShopCategorySelectData() {
    return sxpctx.shopCategories.a
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((e) => ({ value: e.id, label: e.name }));
}
