import { sxpapi } from "../..";
import { SXPContext } from "../../SXPContext";
import { getAuthorization } from "../../helper/AuthenticationHelper";

type QueryData = {
    filterArray: string[];
    filterParams: string[];
    variables: any;
};

interface IInstancePreparer {
    prepareInstanceFromGraphQL(data: any): any;
}

export abstract class GenericQuery<ObjectType, FilterType> {
    protected context: SXPContext;
    protected ctor: new (data: any, context: SXPContext) => ObjectType;
    protected instancePreparer: IInstancePreparer;

    public constructor(
        context: SXPContext,
        ctor: new (data: any, context: SXPContext) => ObjectType,
        instancePreparer: IInstancePreparer
    ) {
        this.context = context;
        this.ctor = ctor;
        this.instancePreparer = instancePreparer;
    }

    public async fetchMany(
        filter: FilterType,
        limit: number = -1,
        sort?: string
    ) {
        const data = this.getQueryData(filter);

        const params = this.getParams(data);
        const graphQLObject = this.getGraphQLName();
        const graphQLFilter = this.getFilter(data);
        const graphQLType = this.getGraphQLType();

        const graphQLSort = sort ? `sort: ["${sort}"]` : "";
        const graphQLPagination = ["limit: " + limit, graphQLSort].join(" ");

        const query = /* GraphQL */ `
            query get ${params} {
                ${graphQLObject}(
                    ${graphQLPagination}
                    ${graphQLFilter}
                ) ${graphQLType}
            }
        `;

        return sxpapi
            .graphql(query, data.variables, this.getToken())
            .then((response) => {
                return response.data.data[this.getGraphQLName()].map(
                    (e: any) =>
                        new this.ctor(
                            this.instancePreparer.prepareInstanceFromGraphQL(e),
                            this.context
                        )
                ) as ObjectType[];
            });
    }

    public async fetchManyWithPagination(
        filter: FilterType,
        limit: number,
        page: number,
        sort?: string
    ) {
        const data = this.getQueryData(filter);

        const params = this.getParams(data);
        const graphQLObject = this.getGraphQLName();

        const graphQLSort = sort ? `sort: ["${sort}"]` : "";
        const graphQLPagination = [
            "limit: " + limit,
            "page: " + page,
            graphQLSort,
        ].join(" ");

        const graphQLFilter = this.getFilter(data);
        const graphQLType = this.getGraphQLType();
        const graphQLMetadata = this.getMetadata(graphQLFilter);

        const query = /* GraphQL */ `
            query get ${params} {
                ${graphQLObject}(
                    ${graphQLPagination}
                    ${graphQLFilter}
                ) ${graphQLType}
                ${graphQLMetadata}
            }
        `;

        return sxpapi
            .graphql(query, data.variables, this.getToken())
            .then((response) => {
                return {
                    data: response.data.data[this.getGraphQLName()].map(
                        (e: any) =>
                            new this.ctor(
                                this.instancePreparer.prepareInstanceFromGraphQL(
                                    e
                                ),
                                this.context
                            )
                    ) as ObjectType[],
                    meta: {
                        total_count: response.data.data.meta[0].count.id,
                    },
                };
            });
    }

    public async fetch(filter: FilterType) {
        return this.fetchMany(filter).then((objects) => {
            if (objects.length === 0) {
                return undefined;
            }

            return objects[0];
        });
    }

    public async fetchRevisions(
        id: string,
        limit: number = 10,
        page: number = 1
    ) {
        return sxpapi
            .get(
                "/revisions?filter[_and][0][collection][_eq]=" +
                    this.getGraphQLName() +
                    "&filter[_and][1][item][_eq]=" +
                    id +
                    "&sort=-id&limit=" +
                    limit +
                    "&page=" +
                    page +
                    "&fields[]=data&meta[]=filter_count",
                this.getToken()
            )
            .then((response) => {
                if (response.data?.data) {
                    return response.data as {
                        data: { data: any }[];
                        meta: { filter_count: number };
                    };
                } else {
                    throw new Error(
                        "Cannot fetch revision " + this.getObjectTypeName()
                    );
                }
            });
    }

    public async create(payload: any) {
        return sxpapi
            .post(this.getRestEndPoint(), payload, this.getToken())
            .then((response) => {
                if (response.data?.data?.id) {
                    return response.data.data.id as string;
                } else {
                    throw new Error(
                        "Cannot create " + this.getObjectTypeName()
                    );
                }
            });
    }

    public async update(payload: any) {
        return sxpapi
            .patch(
                this.getRestEndPoint() + "/" + payload.id,
                payload,
                this.getToken()
            )
            .then((response) => {
                if (response.data?.data?.id) {
                    return response.data.data.id as string;
                } else {
                    throw new Error(
                        "Cannot update " + this.getObjectTypeName()
                    );
                }
            });
    }

    public async delete(id: string) {
        return sxpapi
            .delete(this.getRestEndPoint() + "/" + id, null, this.getToken())
            .then((response) => {});
    }

    public static prepareInstanceFromGraphQL(data: any) {
        return data;
    }

    public abstract getGraphQLType(): string;

    protected abstract getQueryData(filter: FilterType): QueryData;

    protected abstract getObjectTypeName(): string;

    protected abstract getGraphQLName(): string;

    protected abstract getRestEndPoint(): string;

    public abstract fillContext(filter: FilterType, override: boolean): void;

    private getMetadata(graphQLFilter: string) {
        return /* GraphQL */ `
            meta: ${this.getGraphQLName()}_aggregated(
                ${graphQLFilter}
            ) {
                count{id: id}
            }
        `;
    }

    private getParams(data: QueryData) {
        return data.filterParams.length === 0
            ? ""
            : "(" + data.filterParams.join(", ") + ")";
    }

    private getFilter(data: QueryData) {
        return /* GraphQL */ `
            filter: {
                _and: [
                    ${data.filterArray.join("\n")}
                ]
            }
        `;
    }

    private getToken() {
        return getAuthorization(this.context);
    }
}
