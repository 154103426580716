import { SXPContext } from "../../SXPContext";
import {
    changeDateTimeZone,
    createDateWithTimeZone,
    dateToLocalString,
} from "../../helper/DateHelper";
import { GenericQuery } from "./GenericQuery";

export type CalendarFilter = {
    id?: string | string[];
    name?: string;
    region?: string;
    weekday?: string;
    startTimeHour?: string;
    startTimeMinute?: string;
    endTimeHour?: string;
    endTimeMinute?: string;
    firstDateGreaterEquals?: string;
    firstDateLesserEquals?: string;
    lastDateGreaterEquals?: string;
    lastDateLesserEquals?: string;
    format?: string;
    suffix?: string;
    extra?: string;
    isValid?: boolean;
};

export class Calendar {
    private context: SXPContext;

    id: string;
    kind: "Calendar" = "Calendar";
    name: string;
    suffix: string;
    extra: string;
    format: "weekly" | "camp";
    events: {
        start: string;
        end: string;
    }[];
    region: string;

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
        this.context = context;
    }

    public cloneDataOnly() {
        const { context, ...clone } = this as any;

        return clone;
    }

    public getRegion() {
        return this.context.regions.d[this.region];
    }

    public getStartDate(index: number, timezone?: string) {
        if (timezone) {
            const date = createDateWithTimeZone(
                new Date(this.events[index].start),
                this.getRegion().timezone
            );

            return changeDateTimeZone(date, timezone);
        } else {
            return createDateWithTimeZone(
                new Date(this.events[index].start),
                this.getRegion().timezone
            );
        }
    }

    public getEndDate(index: number, timezone?: string) {
        if (timezone) {
            const date = createDateWithTimeZone(
                new Date(this.events[index].end),
                this.getRegion().timezone
            );

            return changeDateTimeZone(date, timezone);
        } else {
            return createDateWithTimeZone(
                new Date(this.events[index].end),
                this.getRegion().timezone
            );
        }
    }

    public getScheduleId() {
        const date = this.getStartDate(0);
        const dateOfCalendar = changeDateTimeZone(
            date,
            this.getRegion().timezone
        );

        return [
            dateOfCalendar.getDay(),
            dateOfCalendar.getHours().toString().padStart(2, "0"),
            dateOfCalendar.getMinutes().toString().padStart(2, "0"),
        ].join("");
    }

    public getWeekId() {
        const date = this.getStartDate(0);
        const dateQC = changeDateTimeZone(date, "America/Montreal");

        dateQC.setDate(dateQC.getDate() - ((dateQC.getDay() + 6) % 7));

        const dateOfWeek = dateToLocalString(dateQC).split("T")[0];

        return [this.format, dateOfWeek].join(".");
    }
}

export class CalendarQuery extends GenericQuery<Calendar, CalendarFilter> {
    constructor(context: SXPContext) {
        super(context, Calendar, CalendarQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.format = data.format.id;
        data.region = data.region.id;

        return data;
    }

    public async fillContext(
        filter: CalendarFilter,
        override: boolean = false
    ) {
        throw new Error("No context for bucket groups");
    }

    public static getName() {
        return "CalendarQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                name
                suffix
                format {
                    id
                }
                events(sort: ["start"]) {
                    start
                    end
                }
                region {
                    id
                }
                extra
            }
        `;
    }

    protected getQueryData(filter: CalendarFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            if (Array.isArray(filter.id)) {
                data.filterArray.push(/* GraphQL */ `
                { 
                    id: { 
                        _in: $id
                    } 
                }
            `);
                data.filterParams.push("$id: [String]");
            } else {
                data.filterArray.push(/* GraphQL */ `
                {
                    id: {
                        _eq: $id
                    }
                }
            `);
                data.filterParams.push("$id: String");
            }
        }

        if (filter.name) {
            data.filterArray.push(/* GraphQL */ `
            {
                name: {
                    _icontains: $name
                }
            }
        `);
            data.filterParams.push("$name: String");
        }

        if (filter.region) {
            data.filterArray.push(/* GraphQL */ `
            {
                region: {
                    id: {
                        _eq: $region
                    }
                }
            }
        `);
            data.filterParams.push("$region: String");
        }

        if (filter.weekday) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_first_event_start_func:{
                        weekday: {
                            _eq: $weekday
                        }
                    }
                }
            `);
            data.filterParams.push("$weekday: GraphQLStringOrFloat");
        }

        if (filter.startTimeHour) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_first_event_start_func:{
                        hour: {
                            _eq: $startTimeHour
                        }
                    }
                }
            `);
            data.filterParams.push("$startTimeHour: GraphQLStringOrFloat");
        }

        if (filter.startTimeMinute) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_first_event_start_func:{
                        minute: {
                            _eq: $startTimeMinute
                        }
                    }
                }
            `);
            data.filterParams.push("$startTimeMinute: GraphQLStringOrFloat");
        }

        if (filter.endTimeHour) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_last_event_end_func:{
                        hour: {
                            _eq: $endTimeHour
                        }
                    }
                }
            `);
            data.filterParams.push("$endTimeHour: GraphQLStringOrFloat");
        }

        if (filter.endTimeMinute) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_last_event_end_func:{
                        minute: {
                            _eq: $endTimeMinute
                        }
                    }
                }
            `);
            data.filterParams.push("$endTimeMinute: GraphQLStringOrFloat");
        }

        if (filter.firstDateGreaterEquals) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_first_event_start:{
                        _gte: $firstDateGreaterEquals
                    }
                }
            `);
            data.filterParams.push("$firstDateGreaterEquals: String");
        }

        if (filter.firstDateLesserEquals) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_first_event_start:{
                        _lte: $firstDateLesserEquals
                    }
                }
            `);
            data.filterParams.push("$firstDateLesserEquals: String");
        }

        if (filter.lastDateGreaterEquals) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_last_event_end: {
                        _gte: $lastDateGreaterEquals
                    }
                }
            `);
            data.filterParams.push("$lastDateGreaterEquals: String");
        }

        if (filter.lastDateLesserEquals) {
            data.filterArray.push(/* GraphQL */ `
                {
                    calc_last_event_end: {
                        _lte: $lastDateLesserEquals
                    }
                }
            `);
            data.filterParams.push("$lastDateLesserEquals: String");
        }

        if (filter.format) {
            data.filterArray.push(/* GraphQL */ `
            {
                format: {
                    id: {
                        _eq: $format
                    }
                }
            }
        `);
            data.filterParams.push("$format: String");
        }

        if (filter.isValid) {
            data.filterArray.push(/* GraphQL */ `
                {
                    format: {
                        id: {
                            _nnull: true
                        }
                    }
                }
            `);
        }

        if (filter.suffix) {
            data.filterArray.push(/* GraphQL */ `
                {
                    suffix: {
                        _contains: $suffix
                    }
                }
            `);
            data.filterParams.push("$suffix: String");
        }

        if (filter.extra) {
            data.filterArray.push(/* GraphQL */ `
                {
                    extra: {
                        _contains: $extra
                    }
                }
            `);
            data.filterParams.push("$extra: String");
        }

        data.variables = {
            id: filter.id,
            name: filter.name,
            region: filter.region,
            weekday: filter.weekday,
            startTimeHour: filter.startTimeHour,
            startTimeMinute: filter.startTimeMinute,
            endTimeHour: filter.endTimeHour,
            endTimeMinute: filter.endTimeMinute,
            firstDateGreaterEquals: filter.firstDateGreaterEquals,
            firstDateLesserEquals: filter.firstDateLesserEquals,
            lastDateGreaterEquals: filter.lastDateGreaterEquals,
            lastDateLesserEquals: filter.lastDateLesserEquals,
            format: filter.format,
            suffix: filter.suffix,
            extra: filter.extra,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "Calendar";
    }

    protected getGraphQLName(): string {
        return "calendars";
    }

    protected getRestEndPoint(): string {
        return "/items/calendars";
    }
}
