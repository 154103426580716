import { AuthToken } from "./api/sxpapi";
import { Account } from "./db/api-v2/AccountQuery";
import { CalendarFormat } from "./db/api-v2/CalendarFormatQuery";
import { CourseCategory } from "./db/api-v2/CourseCategoryQuery";
import { Course } from "./db/api-v2/CourseQuery";
import { Curriculum } from "./db/api-v2/CurriculumQuery";
import { Language } from "./db/api-v2/LanguageQuery";
import { Location } from "./db/api-v2/LocationQuery";
import { Participant } from "./db/api-v2/ParticipantQuery";
import { Region } from "./db/api-v2/RegionQuery";
import { Registration } from "./db/api-v2/RegistrationQuery";
import { ShopCategory } from "./db/api-v2/ShopCategoryQuery";
import { User } from "./db/api-v2/UserQuery";
import { WebsiteConfiguration } from "./db/api-v2/WebsiteConfigurationQuery";
import { ArrayDict } from "./helper/ArrayHelper";

export class SXPContext {
    private queries: any = {};

    public language: string = "fr-CA";

    public authorization: string = "";
    public authToken: AuthToken | undefined = undefined;

    public account?: Account;
    public calendarFormats: ArrayDict<CalendarFormat> = { a: [], d: {} };
    public courses: ArrayDict<Course> = { a: [], d: {} };
    public courseCategories: ArrayDict<CourseCategory> = { a: [], d: {} };
    public curriculums: ArrayDict<Curriculum> = { a: [], d: {} };
    public languages: ArrayDict<Language> = { a: [], d: {} };
    public locations: ArrayDict<Location> = { a: [], d: {} };
    public participant?: Participant;
    public regions: ArrayDict<Region> = { a: [], d: {} };
    public registrations: ArrayDict<Registration> = { a: [], d: {} };
    public shopCategories: ArrayDict<ShopCategory> = { a: [], d: {} };
    public user?: User;
    public websiteConfiguration: WebsiteConfiguration = {};

    public getQuery<T>(queryCls: {
        new (context: SXPContext): T;
        getName(): string;
    }): T {
        if (this.queries[queryCls.getName()] === undefined) {
            this.queries[queryCls.getName()] = new queryCls(this);
        }

        return this.queries[queryCls.getName()];
    }
}
