import { SXPContext } from "../../SXPContext";
import { GenericQuery } from "./GenericQuery";

export type AccountFilter = {
    id?: string;
    ownerId?: string;
    isValid?: boolean;
};

export class Account {
    private context: SXPContext;

    id: string;
    kind: "Account" = "Account";
    first_name: string;
    last_name: string;
    city: string;
    state: string;
    country: string;
    email: string;
    phone: string;
    language: string;
    selected_participant: string;
    owner: string;
    region: string;

    constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
        this.context = context;
    }

    public cloneDataOnly() {
        const { context, ...clone } = this as any;

        return clone;
    }

    getRegion() {
        return this.context.regions.d[this.region];
    }
}

export class AccountQuery extends GenericQuery<Account, AccountFilter> {
    constructor(context: SXPContext) {
        super(context, Account, AccountQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.language = data.language.code;
        data.region = data.region.id;
        data.selected_participant = data.selected_participant?.id;
        data.owner = data.owner.id;

        return data;
    }

    public async fillContext(filter: AccountFilter, override: boolean = false) {
        if (this.context.account === undefined || override) {
            return this.fetch(filter).then((data) => {
                return (this.context.account = data);
            });
        }
    }

    public static getName() {
        return "AccountQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                first_name
                last_name
                city
                state
                country
                email
                phone
                language {
                    code
                }
                selected_participant {
                    id
                }
                owner {
                    id
                }
                region {
                    id
                }
            }
        `;
    }

    protected getQueryData(filter: AccountFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.ownerId) {
            data.filterArray.push(/* GraphQL */ `
                { 
                    owner: { 
                        id: { 
                            _eq: $ownerId
                        } 
                    } 
                }
            `);
            data.filterParams.push("$ownerId: String");
        }

        if (filter.id) {
            data.filterArray.push(/* GraphQL */ `
                { 
                    id: { 
                        _eq: $id
                    } 
                }
            `);
            data.filterParams.push("$id: String");
        }

        if (filter.isValid) {
            data.filterArray.push(/* GraphQL */ `
                {
                    _and: [
                        {
                            language: {
                                code: {
                                    _nnull: true
                                }
                            }
                        }
                        {
                            selected_participant: {
                                id: {
                                    _nnull: true
                                }
                            }
                        }
                    ]
                }
            `);
        }

        data.variables = {
            id: filter.id,
            ownerId: filter.ownerId,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "Account";
    }

    protected getGraphQLName(): string {
        return "accounts";
    }

    protected getRestEndPoint(): string {
        return "/items/accounts";
    }
}
