import { User } from "@sxp-api-lib/index";

type RoleType = "admin" | "tester";

const admin = process.env.REACT_APP_API_ROLE_ADMIN;
const tester = process.env.REACT_APP_API_ROLE_TESTER;
const superAdmin = process.env.REACT_APP_API_USER_SUPER_ADMIN;

export function isUserRole(user: User | undefined, role: RoleType) {
    if (!user) {
        return false;
    }

    if (!admin || !tester) {
        return false;
    }

    if (role === "admin") {
        return [admin].includes(user.role);
    }

    if (role === "tester") {
        return [admin, tester].includes(user.role);
    }
}

export function isUserSuperAdmin(user: User | undefined) {
    if (!user) {
        return false;
    }

    return user.id === superAdmin;
}
