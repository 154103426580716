import { sxpapi } from "../..";
import { Calendar } from "../api-v2/CalendarQuery";

const realGroupType = /* GraphQL */ `
    {
        id
        teacher {
            id
            first_name
            last_name
        }
        location {
            id
        }
        calendar {
            id
            format {
                id
            }
            events(sort: ["start"]) {
                start
                end
            }
            region {
                id
            }
        }
    }
`;

export type RealGroupFilter = {
    id?: string | string[];
    locationId?: string;
    isValid?: boolean;
};

export type RealGroup = {
    id: string;
    teacher: {
        id: string;
        first_name: string;
        last_name: string;
    };
    location: string;
    calendar: Calendar;
};

export function fetchRealGroups(
    filter: RealGroupFilter,
    authorization: string = ""
) {
    const filterArray = [] as string[];
    const filterParams = [] as string[];

    if (filter.id) {
        if (Array.isArray(filter.id)) {
            filterArray.push(/* GraphQL */ `
                { 
                    id: { 
                        _in: $id
                    } 
                }
            `);
            filterParams.push("$id: [String]");
        } else {
            filterArray.push(/* GraphQL */ `
                {
                    id: {
                        _eq: $id
                    }
                }
            `);
            filterParams.push("$id: String");
        }
    }

    if (filter.locationId) {
        filterArray.push(/* GraphQL */ `
            {
                location: {
                    id: {
                        _eq: $locationId
                    }
                }
            }
        `);
        filterParams.push("$locationId: String");
    }

    if (filter.isValid) {
        filterArray.push(/* GraphQL */ `
            {
                calendar: {
                    format: {
                        id: {
                            _nnull: true
                        }
                    }
                }
            }
        `);
    }

    const params =
        filterParams.length === 0 ? "" : "(" + filterParams.join(", ") + ")";

    const query = /* GraphQL */ `
        query get ${params} {
            real_groups(
                filter: {
                    _and: [
                        ${filterArray.join("\n")}
                    ]
                }
            ) ${realGroupType}
        }
    `;

    const variables = {
        id: filter.id,
        locationId: filter.locationId,
    };

    return sxpapi.graphql(query, variables, authorization).then((response) => {
        return response.data.data.real_groups.map((e: any) =>
            createRealGroupObjectGraphQL(e)
        ) as RealGroup[];
    });
}

export function fetchRealGroup(
    filter: RealGroupFilter,
    authorization: string = ""
) {
    return fetchRealGroups(filter, authorization).then((realGroups) => {
        if (realGroups.length === 0) {
            return undefined;
        }

        return realGroups[0];
    });
}

export function createRealGroup(payload: any, authorization: string) {
    return sxpapi
        .post("/items/real_groups", payload, authorization)
        .then((response) => {
            if (response.data?.data?.id) {
                return response.data.data.id as string;
            } else {
                throw new Error("Cannot create real group");
            }
        });
}

export function updateRealGroup(payload: any, authorization: string) {
    return sxpapi
        .patch("/items/real_groups/" + payload.id, payload, authorization)
        .then((response) => {
            if (response.data?.data?.id) {
                return response.data.data.id as string;
            } else {
                throw new Error("Cannot update real group");
            }
        });
}

export function createRealGroupPayload(data: RealGroup): any {
    let payload: any = { ...data };

    payload.calendar = payload.calendar?.id || "";
    payload.teacher = payload.teacher?.id || "";

    return payload;
}

export function createRealGroupObjectGraphQL(data: any) {
    data.location = data.location.id;
    data.teacher = data.teacher.id;
    data.calendar.format = data.calendar.format.id;

    return data as RealGroup;
}
