import { User } from "@sxp-api-lib/index";
import { AuthToken } from "@sxp-api-lib/api/sxpapi";
import React from "react";

export interface IApp {
    [key: string]: any;

    user: User | undefined;
}

const defaultApp = {
    user: undefined,
};

export type AppContextType = {
    data: IApp;
    setState: (newState: any) => void;
};

export const AppContext = React.createContext<AppContextType>({
    data: defaultApp,
    setState: (newState: any) => {},
});

interface AppProviderProps {
    children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [data, setData] = React.useState<IApp>(defaultApp);

    const setState = (newState: any) => {
        setData({ ...data, ...newState });
    };

    return (
        <AppContext.Provider
            value={{
                data,
                setState,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
