import { BucketGroupQuery, SXPConfig } from "@sxp-api-lib/index";
import { sxpctx } from "..";

export async function updateBucketGroupName(id: string) {
    return sxpctx
        .getQuery(BucketGroupQuery)
        .fetch({ id })
        .then((bucketGroup) => {
            const nameParts = [];
            nameParts.push(bucketGroup.calendar.name);
            nameParts.push(bucketGroup.getLocation().short_name);
            nameParts.push(
                bucketGroup
                    .getCurriculums()
                    .map((e) => e.short_name)
                    .join(" ")
            );

            const name = nameParts.join(" - ");

            return sxpctx.getQuery(BucketGroupQuery).update({ id, name });
        });
}
