import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Flex, Group, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import {
    Calendar,
    CalendarFilter,
    CalendarQuery,
    Participant,
    ParticipantFilter,
    ParticipantQuery,
    SXPContext,
    dateToLocalString,
} from "@sxp-api-lib/index";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import i18next from "i18next";
import {
    MRT_ColumnFiltersState,
    MRT_SortingState,
    type MRT_ColumnDef,
} from "mantine-react-table";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AppContext } from "../../AppContext";
import CustomMantineReactTable from "../../components/CustomMantineReactTable";
import { CustomMantineReactTableParams } from "../../components/CustomMantineReactTable/CustomMantineReactTable";
import { postProcessCalendarPayload } from "../../helpers/CalendarHelper";
import { isUserSuperAdmin } from "../../helpers/UserHelper";
import { sxpctx } from "../..";
import { Eye, Viewfinder } from "tabler-icons-react";

type ParticipantManagerInnerType = {
    id: string;
    account: string;
    firstName: string;
    lastName: string;
};

export default function ParticipantManager() {
    const { t } = useTranslation();
    const context = useContext(AppContext);

    const [refreshLoading, setRefreshLoading] = useState(false);

    const queryClient = useQueryClient();

    const deleteProduct = useMutation({
        mutationFn: async (id: string) => {
            return await sxpctx.getQuery(ParticipantQuery).delete(id);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["participants"] });
        },
    });

    const useGetData = ({
        columnFilters,
        sorting,
        pagination,
    }: CustomMantineReactTableParams) => {
        const filter = createQueryFilter(columnFilters);
        const sort = getSort(sorting);

        const fetchParticipants = async () => {
            const promises = [];

            promises.push(
                sxpctx
                    .getQuery(ParticipantQuery)
                    .fetchManyWithPagination(
                        filter,
                        pagination.pageSize,
                        pagination.pageIndex + 1,
                        sort
                    )
                    .then((response) => {
                        return {
                            data: response.data.map((e) =>
                                prepareCalendarForTableView(e)
                            ),
                            meta: response.meta,
                        };
                    })
            );

            return Promise.all(promises).then(([participants]) => {
                return participants as {
                    data: ParticipantManagerInnerType[];
                    meta: { total_count: number };
                };
            });
        };

        return useQuery<{
            data: ParticipantManagerInnerType[];
            meta: { total_count: number };
        }>({
            queryKey: ["participants", pagination, columnFilters, sorting],
            queryFn: () => fetchParticipants(),
            keepPreviousData: true,
            staleTime: 30_000,
            refetchOnWindowFocus: false,
        });
    };

    const columns: MRT_ColumnDef<ParticipantManagerInnerType>[] = [
        {
            accessorKey: "id",
            header: "ID",
            enableHiding: true,
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "firstName",
            header: t("common.firstName"),
            enableSorting: true,
            enableEditing: false,
        },
        {
            accessorKey: "lastName",
            header: t("common.lastName"),
            enableSorting: true,
            enableEditing: false,
        },
    ];

    return (
        <CustomMantineReactTable<ParticipantManagerInnerType>
            id={"calendar-manager"}
            columns={columns}
            useGetData={useGetData}
            renderRowActions={({ cell, row, table }) => (
                <Flex gap="md">
                    <Tooltip label="Edit">
                        <ActionIcon
                            onClick={() => {
                                window.location.hash =
                                    "/participant-inspector/" + row.original.id;
                            }}
                        >
                            <IconEdit />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Subscriber area">
                        <ActionIcon
                            onClick={() => {
                                window.location.hash =
                                    "/participant-subscriber-area/" +
                                    row.original.account;
                            }}
                        >
                            <Eye />
                        </ActionIcon>
                    </Tooltip>
                </Flex>
            )}
            renderTopToolbarCustomActions={({ table }) => null}
        />
    );
}

function prepareCalendarForTableView(participant: Participant) {
    return {
        id: participant.id,
        account: participant.account,
        firstName: participant.first_name,
        lastName: participant.last_name,
    };
}

function createQueryFilter(columnFilters: MRT_ColumnFiltersState) {
    const filter = {} as ParticipantFilter;

    const firstName = columnFilters.find((e) => e.id === "firstName");
    if (firstName) {
        filter.firstName = firstName.value as string;
    }

    const lastName = columnFilters.find((e) => e.id === "lastName");
    if (lastName) {
        filter.lastName = lastName.value as string;
    }

    return filter;
}

function getSort(sortArray: MRT_SortingState) {
    if (sortArray.length === 0) {
        return undefined;
    }

    const sort = sortArray[0];

    const order = sort.desc ? "-" : "";

    if (sort.id === "firstName") {
        return order + "first_name";
    }

    if (sort.id === "lastName") {
        return order + "last_name";
    }
    return undefined;
}
