import {
    MRT_Cell,
    MRT_ColumnFiltersState,
    MRT_ColumnSizingState,
    MRT_DensityState,
    MRT_PaginationState,
    MRT_Row,
    MRT_SortingState,
    MRT_TableInstance,
    MRT_VisibilityState,
    MantineReactTable,
    type MRT_ColumnDef,
} from "mantine-react-table";
import { ReactNode, useEffect, useState } from "react";
import { UseQueryResult, useIsMutating } from "react-query";

export type CustomMantineReactTableParams = {
    columnFilters: MRT_ColumnFiltersState;
    sorting: MRT_SortingState;
    pagination: MRT_PaginationState;
};

export default function CustomMantineReactTable<
    TData extends Record<string, any> = {}
>(props: {
    id: string;
    columns: MRT_ColumnDef<TData>[];
    actionCount?: number;
    renderRowActions: (props: {
        cell: MRT_Cell<TData>;
        row: MRT_Row<TData>;
        table: MRT_TableInstance<TData>;
    }) => ReactNode;
    renderTopToolbarCustomActions: (props: {
        table: MRT_TableInstance<TData>;
    }) => ReactNode;
    useGetData: (params: CustomMantineReactTableParams) => UseQueryResult<
        {
            data: TData[];
            meta: { total_count: number };
        },
        unknown
    >;
}) {
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        loadColumnFiltersFromStorage(props.id + ".columnFilters") || []
    );
    const [sorting, setSorting] = useState<MRT_SortingState>(
        loadFromStorage(props.id + ".sorting") || []
    );
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });
    const [density, setDensity] = useState<MRT_DensityState>(
        loadFromStorage(props.id + ".density") || "md"
    );
    const [columnVisibility, setColumnVisibility] =
        useState<MRT_VisibilityState>(
            loadFromStorage(props.id + ".visibility") || { id: false }
        );
    const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(
        loadFromStorage(props.id + ".sizing") || {}
    );

    const actionCount = props.actionCount ? props.actionCount : 3;

    const { data, isError, isFetching, isLoading, refetch } = props.useGetData({
        columnFilters,
        sorting,
        pagination,
    });

    const isMutating = useIsMutating();

    useEffect(() => {
        localStorage.setItem(
            props.id + ".columnFilters",
            JSON.stringify(columnFilters)
        );
    }, [columnFilters]);

    useEffect(() => {
        localStorage.setItem(props.id + ".sorting", JSON.stringify(sorting));
    }, [sorting]);

    useEffect(() => {
        localStorage.setItem(props.id + ".density", JSON.stringify(density));
    }, [density]);

    useEffect(() => {
        localStorage.setItem(
            props.id + ".visibility",
            JSON.stringify(columnVisibility)
        );
    }, [columnVisibility]);

    useEffect(() => {
        localStorage.setItem(
            props.id + ".sizing",
            JSON.stringify(columnSizing)
        );
    }, [columnSizing]);

    function loadColumnFiltersFromStorage(key: string) {
        const data = localStorage.getItem(key);

        if (!data) {
            return undefined;
        }

        const json = JSON.parse(data);

        // Date needs to be parsed manually
        const parsedJSON = json.map((e: any) => {
            if (
                props.columns.find(
                    (column) =>
                        column.accessorKey === e?.id &&
                        (column.filterVariant === "date" ||
                            column.filterVariant === "date-range")
                )
            ) {
                e.value = e.value.map((filter: any) =>
                    filter ? new Date(filter) : null
                );
            }

            return e;
        });

        return parsedJSON;
    }

    function loadFromStorage(key: string) {
        const data = localStorage.getItem(key);

        if (!data) {
            return undefined;
        }

        return JSON.parse(data);
    }

    return (
        <MantineReactTable
            columns={props.columns}
            data={data?.data ?? []}
            columnFilterDisplayMode="subheader"
            enableRowActions={true}
            manualPagination={true}
            manualFiltering={true}
            manualSorting={true}
            onPaginationChange={setPagination}
            onColumnFiltersChange={setColumnFilters}
            onSortingChange={setSorting}
            onDensityChange={setDensity}
            onColumnVisibilityChange={setColumnVisibility}
            onColumnSizingChange={setColumnSizing}
            rowCount={data?.meta?.total_count ?? 0}
            enableGlobalFilter={false}
            mantineLoadingOverlayProps={{ zIndex: 100 }}
            enableEditing
            editDisplayMode="table"
            enableColumnResizing={true}
            columnResizeMode="onChange"
            initialState={{
                columnVisibility: {
                    id: false,
                },
                showColumnFilters: true,
            }}
            state={{
                isLoading: isLoading,
                pagination,
                sorting,
                columnFilters,
                density,
                columnVisibility,
                columnSizing,
            }}
            renderRowActions={props.renderRowActions}
            displayColumnDefOptions={{
                "mrt-row-actions": {
                    header: "Actions",
                    enableResizing: false,
                    size: 28 * actionCount + 16 * (actionCount - 1),
                },
            }}
            renderTopToolbarCustomActions={props.renderTopToolbarCustomActions}
        />
    );
}
