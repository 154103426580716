import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Flex, Group, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import {
    BucketGroup,
    BucketGroupFilter,
    BucketGroupQuery,
    SXPContext,
} from "@sxp-api-lib/index";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import i18next from "i18next";
import {
    MRT_ColumnFiltersState,
    MRT_SortingState,
    type MRT_ColumnDef,
} from "mantine-react-table";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AppContext } from "../../AppContext";
import CustomMantineReactTable, {
    CustomMantineReactTableParams,
} from "../../components/CustomMantineReactTable/CustomMantineReactTable";
import { isUserSuperAdmin } from "../../helpers/UserHelper";
import { updateBucketGroupName } from "../../helpers/BucketGroupHelper";
import { sxpctx } from "../..";
import i18n from "../../i18n";

type BucketGroupManagerInnerType = {
    id: string;
    location: string;
    curriculums: string;
};

export default function BucketGroupManager() {
    const { t } = useTranslation();
    const context = useContext(AppContext);

    const [refreshLoading, setRefreshLoading] = useState(false);

    const queryClient = useQueryClient();

    const deleteProduct = useMutation({
        mutationFn: async (id: string) => {
            return await sxpctx.getQuery(BucketGroupQuery).delete(id);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["bucketGroups"] });
        },
    });

    const useGetData = ({
        columnFilters,
        sorting,
        pagination,
    }: CustomMantineReactTableParams) => {
        const filter = createQueryFilter(columnFilters);
        const sort = getSort(sorting);

        const fetchBucketGroups = async () => {
            const promises = [];

            promises.push(
                sxpctx
                    .getQuery(BucketGroupQuery)
                    .fetchManyWithPagination(
                        filter,
                        pagination.pageSize,
                        pagination.pageIndex + 1,
                        sort
                    )
                    .then((response) => {
                        return {
                            data: response.data.map((e) =>
                                prepareBucketGroupForTableView(e)
                            ),
                            meta: response.meta,
                        };
                    })
            );

            return Promise.all(promises).then(([calendars]) => {
                return calendars as {
                    data: BucketGroupManagerInnerType[];
                    meta: { total_count: number };
                };
            });
        };

        return useQuery<{
            data: BucketGroupManagerInnerType[];
            meta: { total_count: number };
        }>({
            queryKey: ["bucketGroups", pagination, columnFilters, sorting],
            queryFn: () => fetchBucketGroups(),
            keepPreviousData: true,
            staleTime: 30_000,
            refetchOnWindowFocus: false,
        });
    };

    const stockOverrideStateData = Object.entries(
        i18n.getResourceBundle(i18n.language, "").common.stockOverrideState
    ).map((e) => ({ value: e[0], label: e[1] as string }));

    const columns: MRT_ColumnDef<BucketGroupManagerInnerType>[] = [
        {
            accessorKey: "id",
            header: "ID",
            enableHiding: true,
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "calendarName",
            header: t("bucketGroupManager.calendar"),
            enableSorting: true,
            sortDescFirst: true,
            enableEditing: false,
        },
        {
            accessorKey: "location",
            header: t("calendarManager.location"),
            mantineFilterSelectProps: ({ column, table }) => ({
                data: sxpctx.locations.a.map((e) => ({
                    value: e.id,
                    label: e.label,
                })),
            }),
            filterVariant: "select",
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "curriculums",
            header: t("calendarManager.curriculums"),
            mantineFilterMultiSelectProps: ({ column, table }) => ({
                data: sxpctx.curriculums.a.map((e) => ({
                    value: e.id,
                    label: e.label,
                })),
            }),
            filterVariant: "multi-select",
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "shop_categories",
            header: t("common.shopCategory"),
            mantineFilterSelectProps: ({ column, table }) => ({
                data: sxpctx.shopCategories.a
                    .map((e) => ({
                        value: e.id,
                        label: e.label,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)),
            }),
            filterVariant: "multi-select",
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "stock",
            header: t("common.stock"),
            filterVariant: "range",
            enableSorting: false,
            enableEditing: false,
        },
        {
            accessorKey: "stock_override",
            header: t("common.stockOverride"),
            mantineFilterSelectProps: ({ column, table }) => ({
                data: stockOverrideStateData,
            }),
            filterVariant: "select",
            sortDescFirst: true,
            enableEditing: false,
        },
        {
            accessorKey: "locked",
            header: t("common.locked"),
            filterVariant: "checkbox",
            enableSorting: false,
            Cell: ({ cell }) => {
                return cell.getValue() ? t("common.locked") : "";
            },
            enableEditing: false,
        },
    ];

    return (
        <CustomMantineReactTable<BucketGroupManagerInnerType>
            id={"bucket-group-manager"}
            columns={columns}
            useGetData={useGetData}
            renderRowActions={({ cell, row, table }) => (
                <Flex gap="md">
                    <Tooltip label="Edit">
                        <ActionIcon
                            onClick={() => {
                                modals.openContextModal({
                                    modal: "BucketGroupManagerEditModal",
                                    title: t(
                                        "calendarManager.createNewBucketGroup"
                                    ),
                                    closeOnEscape: false,
                                    closeOnClickOutside: false,
                                    size: "lg",
                                    styles: {
                                        inner: {
                                            width: "-webkit-fill-available", // Fixes alignment error with mantine
                                        },
                                    },
                                    innerProps: {
                                        id: row.original.id,
                                    },
                                });
                            }}
                        >
                            <IconEdit />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Delete">
                        <ActionIcon
                            color="red"
                            onClick={() =>
                                modals.openConfirmModal({
                                    title: "Are you sure you want to delete this?",
                                    children: (
                                        <Text>
                                            Are you sure you want to delete{" "}
                                            {row.original.id}? This action
                                            cannot be undone.
                                        </Text>
                                    ),
                                    labels: {
                                        confirm: "Delete",
                                        cancel: "Cancel",
                                    },
                                    confirmProps: { color: "red" },
                                    onConfirm: () => {
                                        deleteProduct.mutate(row.original.id);
                                    },
                                })
                            }
                        >
                            <IconTrash />
                        </ActionIcon>
                    </Tooltip>
                </Flex>
            )}
            renderTopToolbarCustomActions={({ table }) => (
                <Group>
                    <Button
                        onClick={() => {
                            modals.openContextModal({
                                modal: "BucketGroupManagerEditModal",
                                title: t(
                                    "calendarManager.createNewBucketGroup"
                                ),
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                size: "lg",
                                styles: {
                                    inner: {
                                        width: "-webkit-fill-available", // Fixes alignment error with mantine
                                    },
                                },
                                innerProps: {},
                            });
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <span style={{ paddingLeft: "5px" }}>
                            {t("calendarManager.createNew")}
                        </span>
                    </Button>
                    {isUserSuperAdmin(sxpctx.user) && (
                        <Button
                            loading={refreshLoading}
                            onClick={() => {
                                const fetchNext = (
                                    pageIndex: number,
                                    pageSize: number
                                ) => {
                                    sxpctx
                                        .getQuery(BucketGroupQuery)
                                        .fetchManyWithPagination(
                                            {},
                                            pageSize,
                                            pageIndex
                                        )
                                        .then(async (result) => {
                                            if (
                                                pageSize * (pageIndex - 1) <
                                                result.meta.total_count
                                            ) {
                                                for (
                                                    var i = 0;
                                                    i < result.data.length;
                                                    i++
                                                ) {
                                                    await updateBucketGroupName(
                                                        result.data[i].id
                                                    );
                                                }

                                                fetchNext(
                                                    pageIndex + 1,
                                                    pageSize
                                                );
                                            } else {
                                                setRefreshLoading(false);
                                            }
                                        });
                                };

                                setRefreshLoading(true);
                                fetchNext(1, 10);
                            }}
                        >
                            <span style={{ paddingLeft: "5px" }}>
                                REFRESH NAMES
                            </span>
                        </Button>
                    )}
                </Group>
            )}
        />
    );
}

function prepareBucketGroupForTableView(bucketGroup: BucketGroup) {
    return {
        id: bucketGroup.id,
        calendarName: bucketGroup.calendar.name,
        location: bucketGroup.getLocation()?.label || "",
        curriculums: sxpctx.curriculums.a
            .filter((e) => bucketGroup.curriculums.includes(e.id))
            .map((e) => e.label)
            .join(", "),
        locked: bucketGroup.locked,
        stock: bucketGroup.stock,
        stock_override: i18n.t(
            "common.stockOverrideState." + bucketGroup.stock_override
        ),
        shop_categories: sxpctx.shopCategories.a
            .filter((e) => bucketGroup.shop_categories.includes(e.id))
            .map((e) => e.label)
            .join(", "),
    };
}

function createQueryFilter(columnFilters: MRT_ColumnFiltersState) {
    const filter = {} as BucketGroupFilter;

    const calendarName = columnFilters.find((e) => e.id === "calendarName");
    if (calendarName) {
        filter.calendarName = calendarName.value as string;
    }

    const location = columnFilters.find((e) => e.id === "location");
    if (location) {
        filter.locationId = location.value as string;
    }

    const curriculums = columnFilters.find((e) => e.id === "curriculums");
    if (curriculums) {
        filter.curriculumId = curriculums.value as string[];
    }

    const shopCategories = columnFilters.find(
        (e) => e.id === "shop_categories"
    )?.value;
    if (shopCategories && Array.isArray(shopCategories)) {
        filter.shopCategoryId = shopCategories;
    }

    const stock = columnFilters.find((e) => e.id === "stock")?.value;
    if (stock && Array.isArray(stock) && stock.length === 2) {
        const stockMin = parseInt(stock[0]);
        if (stockMin) {
            filter.stockMin = stockMin;
        }

        const stockMax = parseInt(stock[1]);
        if (stockMax) {
            filter.stockMax = stockMax;
        }
    }

    const stockOverride = columnFilters.find((e) => e.id === "stock_override");
    if (stockOverride) {
        filter.stockOverride = stockOverride.value as any;
    }

    const locked = columnFilters.find((e) => e.id === "locked");
    if (locked !== undefined) {
        filter.locked = locked.value === "true";
    }

    return filter;
}

function getSort(sortArray: MRT_SortingState) {
    if (sortArray.length === 0) {
        return undefined;
    }

    const sort = sortArray[0];

    const order = sort.desc ? "-" : "";

    if (sort.id === "calendarName") {
        return order + "calendar.name";
    }

    return undefined;
}
