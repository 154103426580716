// This wrapper was created to make the TimeInput picker works with Chrome

import { useRef } from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon } from "@mantine/core";
import {
    TimeInput as MantineTimeInput,
    TimeInputProps as MantineTimeInputProps,
} from "@mantine/dates";

export default function TimeInput(props: MantineTimeInputProps) {
    const ref = useRef<HTMLInputElement>(null);

    return (
        <>
            <MantineTimeInput
                ref={ref}
                {...props}
                rightSection={
                    <ActionIcon
                        onClick={() => {
                            ref.current?.showPicker();
                        }}
                    >
                        <FontAwesomeIcon icon={faClock} />
                    </ActionIcon>
                }
            />
        </>
    );
}
