import { SXPContext } from "../../SXPContext";
import { arrayToObjectGeneric } from "../../helper/ArrayHelper";
import { GenericQuery } from "./GenericQuery";

export type CurriculumFilter = {
    id?: string;
    languageCode?: string;
    status?: "public" | "hidden" | "archived";
};

export class Curriculum {
    private context: SXPContext;

    id: string;
    kind: "Curriculum" = "Curriculum";
    status: "public" | "hidden" | "archived";
    slug: string;
    short_name: string;
    label: string;
    label_short: string;
    description: string;
    age_min: string;
    age_max: string;
    courses: string[];

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
        this.context = context;
    }

    public cloneDataOnly() {
        const { context, ...clone } = this as any;

        return clone;
    }

    public getCourses() {
        return this.courses
            .map((e) => this.context.courses.d[e])
            .filter((e) => e);
    }

    public getAssociatedRegistrations() {
        return this.getCourses()
            .map((e) => e.getAssociatedRegistration())
            .filter((e) => e);
    }

    public getRegistrationStatus() {
        const registeredCourses = this.context.registrations.a
            .map((e) => e.getCourse())
            .filter((e) => e)
            .filter((e) => e.curriculum === this.id);

        if (registeredCourses.length === 0) {
            return "not-registered";
        }

        const curriculumRegistrations = this.getAssociatedRegistrations();

        if (curriculumRegistrations.length < this.courses.length) {
            return "some-registered";
        }

        if (
            curriculumRegistrations.every((e) => e.getStatus() === "completed")
        ) {
            return "completed";
        }

        return "all-registered";
    }
}

export class CurriculumQuery extends GenericQuery<
    Curriculum,
    CurriculumFilter
> {
    constructor(context: SXPContext) {
        super(context, Curriculum, CurriculumQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.courses = data.courses.map((e: any) => e.id);

        data.label = data.translations[0]?.label ?? "";
        data.label_short = data.translations[0]?.label_short ?? "";
        data.description = data.translations[0]?.description ?? "";

        delete data.translations;

        return data;
    }

    public async fillContext(
        filter: CurriculumFilter,
        override: boolean = false
    ) {
        if (this.context.curriculums.a.length === 0 || override) {
            return this.fetchMany(filter).then((data) => {
                return (this.context.curriculums = arrayToObjectGeneric(
                    data,
                    "id"
                ));
            });
        }
    }

    public static getName() {
        return "CurriculumQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                status
                slug
                short_name
                courses(sort: ["sort_curriculums"]) {
                    id
                }
                age_min
                age_max
                translations(
                    filter: {
                        languages_code: { code: { _eq: $displayLanguage } }
                    }
                ) {
                    label
                    label_short
                    description
                }
            }
        `;
    }

    protected getQueryData(filter: CurriculumFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            data.filterArray.push(/* GraphQL */ `
                {
                    id: {
                        _eq: $id
                    }
                }
            `);
            data.filterParams.push("$id: String");
        }

        if (filter.languageCode) {
            data.filterArray.push(/* GraphQL */ `
                {
                    translations: {
                        languages_code: {
                            code: {
                                _eq: $languageCode
                            }
                        }
                    }
                }
            `);
            data.filterParams.push("$languageCode: String");
        }

        if (filter.status) {
            data.filterArray.push(/* GraphQL */ `
                {
                    status: {
                        _eq: $status
                    }
                }
            `);
            data.filterParams.push("$status: String");
        }

        data.filterParams.push("$displayLanguage: String");

        data.variables = {
            displayLanguage: this.context.language,
            id: filter.id,
            languageCode: filter.languageCode,
            status: filter.status,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "Curriculum";
    }

    protected getGraphQLName(): string {
        return "curriculums";
    }

    protected getRestEndPoint(): string {
        return "/items/curriculums";
    }
}
