import { sxpctx } from "..";

export type CalendarPayload = {
    id: string;
    events: { start: string; end: string }[];
    region: string;
    suffix: string;
    extra: string;
    format: string;
};

export function postProcessCalendarPayload(payload: CalendarPayload) {
    return {
        ...payload,
        name: createCalendarName(payload),
        calc_first_event_start: payload.events[0].start,
        calc_last_event_end: payload.events[payload.events.length - 1].end,
    };
}

export function createCalendarName(payload: CalendarPayload) {
    const nameParts = [] as string[];

    const firstDate = new Date(payload.events[0].start);
    const lastDate = new Date(payload.events[payload.events.length - 1].end);

    const year = parseInt(
        firstDate.toLocaleString("fr-ca", { year: "2-digit" })
    );

    const month = parseInt(
        firstDate.toLocaleString("fr-ca", { month: "numeric" })
    );

    const startYear = month >= 1 && month <= 8 ? year - 1 : year;
    nameParts.push(startYear + "-" + (startYear + 1));

    nameParts.push(sxpctx.regions.d[payload.region]?.short_name);

    nameParts.push(
        (() => {
            switch (payload.format) {
                case "weekly":
                    return "COURS";
                case "camp":
                    return "CAMPS";
                default:
                    return "COURS";
            }
        })()
    );

    nameParts.push(payload.suffix || "");

    const session = nameParts.map((e) => e.toUpperCase()).join(".");

    const weekDay = firstDate
        .toLocaleString("fr-ca", { weekday: "long" })
        .substring(0, 3)
        .toUpperCase();

    const time =
        firstDate.getHours() +
        ":" +
        String(firstDate.getMinutes()).padStart(2, "0");

    const extra = payload.extra.length > 0 ? " (" + payload.extra + ")" : "";

    return session + " - " + weekDay + ". " + time + extra;
}
