import { SXPConfig } from "..";
import { SXPContext } from "../SXPContext";
import { AuthToken } from "../api/sxpapi";

export function getAuthorization(context: SXPContext) {
    if (context.authorization) {
        return context.authorization;
    } else if (context.authToken) {
        return context.authToken.access_token;
    } else {
        return "";
    }
}

export function loadAuthTokenFromCookie(context: SXPContext) {
    if (typeof document !== "undefined") {
        const authToken = getCookie("sxp_authToken");

        if (authToken) {
            context.authToken = JSON.parse(authToken) as AuthToken;
        } else {
            context.authToken = undefined;
        }
    }
}

export function saveAuthTokenToCookie(context: SXPContext) {
    if (typeof document !== "undefined") {
        if (context.authToken) {
            setCookie(
                "sxp_authToken",
                JSON.stringify(context.authToken),
                SXPConfig.authCookieExpiry
            );
        } else {
            setCookie("sxp_authToken", {}, -1);
        }
    }
}

export function needTokenRefresh(context: SXPContext) {
    return (
        context.authToken &&
        context.authToken.expires < new Date().getTime() + 60000
    );
}

function getCookie(name: string) {
    name = name + "=";

    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
}

function setCookie(name: string, value: any, expiry: number) {
    const d = new Date();
    d.setTime(d.getTime() + expiry * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}
