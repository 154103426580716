import {
    BucketGroup,
    BucketGroupQuery,
    Curriculum,
    arrayToObjectGeneric,
    createDateWithTimeZone,
    getNow,
} from "../..";
import { SXPContext } from "../../SXPContext";
import {
    RealGroup,
    createRealGroupObjectGraphQL,
} from "../api/RealGroupsQueries";
import { GenericQuery } from "./GenericQuery";

export type RegistrationFilter = {
    id?: string;
    participantId?: string;
    orderId?: string;
    orderLineItemId?: string;
    isValid?: boolean;
};

export class Registration {
    private context: SXPContext;

    id: string;
    kind: "Registration" = "Registration";
    status: "active" | "paused";
    participant: string;
    bucket_group: BucketGroup | undefined;
    course: string;
    real_group: RealGroup | undefined;
    shop_category: string;
    read_only: boolean;

    public constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
        this.context = context;

        if (data.bucket_group) {
            this.bucket_group = new BucketGroup(data.bucket_group, context);
        }
    }

    public cloneDataOnly() {
        const { context, ...clone } = this as any;

        if (clone.bucket_group) {
            clone.bucket_group = clone.bucket_group.cloneDataOnly();
        }

        return clone;
    }

    public getCalendar() {
        if (this.real_group) {
            return this.real_group.calendar;
        } else if (this.bucket_group) {
            return this.bucket_group.calendar;
        } else {
            return undefined;
        }
    }

    public getCourse() {
        return this.context.courses.d[this.course].getParentEquivalence();
    }

    public getCurriculum(): Curriculum | undefined {
        return this.context.curriculums.d[this.getCourse().curriculum];
    }

    public isModifiableByClient() {
        if (this.bucket_group === undefined) {
            return true;
        }

        if (this.read_only) {
            return false;
        }

        if (this.bucket_group.locked) {
            return false;
        }

        if (this.bucket_group.isOpenByDeadline()) {
            return true;
        }

        return false;
    }

    public getLocation() {
        if (this.real_group) {
            return this.context.locations.d[this.real_group.location];
        } else if (this.bucket_group) {
            return this.context.locations.d[this.bucket_group.location];
        }

        return undefined;
    }

    public getShopCategory() {
        return this.context.shopCategories.d[this.shop_category];
    }

    public getChapter() {
        const course = this.getCourse();

        if (course) {
            return course.getChapter();
        }

        return 0;
    }

    public getStatus() {
        const calendar = this.getCalendar();

        if (!calendar) {
            return "paused";
        }

        const events = calendar.events;
        const timezone = calendar.getRegion().timezone;

        const firstEvent = createDateWithTimeZone(
            new Date(events[0].start),
            timezone
        );
        const lastEvent = createDateWithTimeZone(
            new Date(events[events.length - 1].end),
            timezone
        );

        if (getNow().getTime() < firstEvent.getTime()) {
            return "to-come";
        }

        if (getNow().getTime() > lastEvent.getTime()) {
            return "completed";
        }

        return "in-progress";
    }
}

export class RegistrationQuery extends GenericQuery<
    Registration,
    RegistrationFilter
> {
    constructor(context: SXPContext) {
        super(context, Registration, RegistrationQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        if (data.bucket_group) {
            data.bucket_group = BucketGroupQuery.prepareInstanceFromGraphQL(
                data.bucket_group
            );
        } else {
            data.bucket_group = undefined;
        }

        if (data.real_group) {
            data.real_group = createRealGroupObjectGraphQL(data.real_group);
        } else {
            data.real_group = undefined;
        }

        data.course = data.course.id;
        data.shop_category = data.shop_category.id;
        data.participant = data.participant.id;

        return data;
    }

    public async fillContext(
        filter: RegistrationFilter,
        override: boolean = false
    ) {
        if (this.context.registrations.a.length === 0 || override) {
            return this.fetchMany(filter).then((data) => {
                return (this.context.registrations = arrayToObjectGeneric(
                    data,
                    "id"
                ));
            });
        }
    }

    public static getName() {
        return "RegistrationQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                status
                bucket_group {
                    id
                    location {
                        id
                    }
                    stock
                    stock_override
                    curriculums {
                        curriculums_id {
                            id
                        }
                    }
                    shop_categories {
                        shop_categories_id {
                            id
                        }
                    }
                    calendar {
                        id
                        name
                        format {
                            id
                        }
                        events(sort: ["start"]) {
                            start
                            end
                        }
                        region {
                            id
                        }
                        suffix
                        extra
                    }
                }
                real_group {
                    id
                    location {
                        id
                    }
                    teacher {
                        id
                        first_name
                        last_name
                    }
                    calendar {
                        id
                        name
                        format {
                            id
                        }
                        events(sort: ["start"]) {
                            start
                            end
                        }
                        region {
                            id
                        }
                        suffix
                        extra
                    }
                }
                participant {
                    id
                }
                course {
                    id
                }
                real_group {
                    id
                    teacher {
                        id
                    }
                }
                read_only
                shop_category {
                    id
                }
                payment {
                    id
                }
            }
        `;
    }

    protected getQueryData(filter: RegistrationFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        if (filter.id) {
            data.filterArray.push(/* GraphQL */ `
                {
                    id: {
                        _eq: $id
                    }
                }
            `);
            data.filterParams.push("$id: String");
        }

        if (filter.participantId) {
            data.filterArray.push(/* GraphQL */ `
                { 
                    participant: { 
                        id: { 
                            _eq: $participantId 
                        } 
                    } 
                }
            `);
            data.filterParams.push("$participantId: String");
        }

        if (filter.orderId) {
            data.filterArray.push(/* GraphQL */ `
                { 
                    orders_line_item: { 
                        order: {
                            id: { 
                                _eq: $orderId 
                            } 
                        }
                    } 
                }
            `);
            data.filterParams.push("$orderId: String");
        }

        if (filter.isValid) {
            data.filterArray.push(/* GraphQL */ `
                {
                    _and: [
                        {
                            _or: [
                                {
                                    _and: [
                                        {
                                            bucket_group: {
                                                id: {
                                                    _nnull: true
                                                }
                                            }
                                        }
                                        {
                                            bucket_group: {
                                                calendar: {
                                                    format: {
                                                        id: {
                                                            _nnull: true
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                                {
                                    bucket_group: {
                                        id: {
                                            _null: true
                                        }
                                    }
                                }
                            ]
                        }
                        {
                            shop_category: {
                                id: {
                                    _nnull: true
                                }
                            }
                        }
                        {
                            participant: {
                                id: {
                                    _nnull: true
                                }
                            }
                        }
                        {
                            course: {
                                id: {
                                    _nnull: true
                                }
                            }
                        }
                    ]
                }
            `);
        }

        data.variables = {
            id: filter.id,
            orderId: filter.orderId,
            participantId: filter.participantId,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "Registration";
    }

    protected getGraphQLName(): string {
        return "registrations";
    }

    protected getRestEndPoint(): string {
        return "/items/registrations";
    }
}
