import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
    fr: {
        translation: {
            common: {
                calendar: "Calendrier",
                chapter: "Chapitre",
                curriculum: "Parcours",
                firstName: "Prénom",
                lastName: "Nom",
                location: "Lieu",
                name: "Nom",
                registration: "Inscription",
                shopCategory: "Formule",
                locked: "Verrouillé",
                stock: "Stock",
                stockOverride: "Stock forcé",
                stockOverrideState: {
                    stock_high: "Élevé",
                    stock_low: "Bas",
                    out_of_stock: "Rupture de stock"
                }
            },
            app: {
                title: "Tableau de bord",
                connection: "Connexion",
            },
            menu: {
                title: "Menu",
                logout: "Déconnexion"
            },
            calendarManager: {
                title: "Gestion des calendriers",
                createNew: "Nouveau",
                createNewCamp: "Nouveau calendar de camp",
                createNewWeekly: "Nouveau calendrier de cours hebdo",
                createNewBucketGroup: "Nouveau groupes paniers",
                course: "Cours",
                location: "Lieu",
                curriculums: "Parcours",
                startTime: "Début",
                endTime: "Fin",
                firstDate: "Premier cours",
                lastDate: "Dernier cours",
                weekDay: "Jour",
                region: "Région",
                session: "Session",
                extra: "Info supp.",
                save: "Enregistrer",
                cancel: "Annuler",
                camp: "Camp",
                weekly: "Hebdo",
                format: "Format"
            },
            bucketGroupManager: {
                title: "Gestion des groupes paniers",
                calendar: "Calendrier"
            },
            participantManager: {
                title: "Gestion des participants"
            },
            inspector: {
                title: "Inspecteur",
                subscriberArea: "Espace abonné"
            }
        },
    },
};

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false,
        },
        compatibilityJSON: "v3",
    });

export default i18n;
