import { SXPContext } from "../../SXPContext";
import { arrayToObjectGeneric } from "../../helper/ArrayHelper";
import { GenericQuery } from "./GenericQuery";

export type CalendarFormatFilter = {};

export class CalendarFormat {
    id: string;
    kind: "CalendarFormat" = "CalendarFormat";
    name: string;
    label: string;

    constructor(data: any, context: SXPContext) {
        Object.assign(this, data);
    }
}

export class CalendarFormatQuery extends GenericQuery<
    CalendarFormat,
    CalendarFormatFilter
> {
    constructor(context: SXPContext) {
        super(context, CalendarFormat, CalendarFormatQuery);
    }

    public static prepareInstanceFromGraphQL(data: any) {
        data.label = data.translations[0]?.label ?? "";

        delete data.translations;

        return data;
    }

    public async fillContext(
        filter: CalendarFormatFilter,
        override: boolean = false
    ) {
        if (this.context.calendarFormats.a.length === 0 || override) {
            return this.fetchMany(filter).then((data) => {
                return (this.context.calendarFormats = arrayToObjectGeneric(
                    data,
                    "id"
                ));
            });
        }
    }

    public static getName() {
        return "CalendarFormatQuery";
    }

    public getGraphQLType() {
        return /* GraphQL */ `
            {
                id
                name
                translations(
                    filter: {
                        languages_code: { code: { _eq: $displayLanguage } }
                    }
                ) {
                    label
                }
            }
        `;
    }

    protected getQueryData(filter: CalendarFormatFilter) {
        const data: {
            filterArray: string[];
            filterParams: string[];
            variables: any;
        } = { filterArray: [], filterParams: [], variables: {} };

        data.filterParams.push("$displayLanguage: String");

        data.variables = {
            displayLanguage: this.context.language,
        };

        return data;
    }

    protected getObjectTypeName(): string {
        return "CalendarFormat";
    }

    protected getGraphQLName(): string {
        return "calendars_format";
    }

    protected getRestEndPoint(): string {
        return "/items/calendars_format";
    }
}
